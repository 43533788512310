import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SnackbarKey, SnackbarMessage, SnackbarOrigin, VariantType, useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from './redux-base-hooks';

import { ClickAwayListenerProps } from '@mui/material/ClickAwayListener';
import getStore from '../store';
import { useEffect } from 'react';

/** Custom interface for snackbar options, as not all options can be supported through redux */
interface ISnackbarOptions {
    /**
     * Unique identifier to reference a snackbar.
     * @default random unique string
     */
    key?: SnackbarKey;
    /**
     * Snackbar stays on the screen, unless it is dismissed (programmatically or through user interaction).
     * @default false
     */
    persist?: boolean;

    /**
     * Used to easily display different variant of snackbars. When passed to `SnackbarProvider`
     * all snackbars inherit the `variant`, unless you override it in `enqueueSnackbar` options.
     * @default default
     */
    variant?: VariantType;
    /**
     * Ignores displaying multiple snackbars with the same `message`
     * @default false
     */
    preventDuplicate?: boolean;
    /**
     * The anchor of the `Snackbar`.
     * @default { horizontal: left, vertical: bottom }
     */
    anchorOrigin?: SnackbarOrigin;
    /**
     * The number of milliseconds to wait before automatically calling the
     * `onClose` function. By default snackbars get closed after 5000 milliseconds.
     * Set autoHideDuration to 'null' if you don't want snackbars to automatically close.
     * Alternatively pass `persist: true` in the options parameter of enqueueSnackbar.
     * @default 5000
     */
    autoHideDuration?: number | null;
    /**
     * @ignore
     * Properties applied to ClickAwayListener component
     */
    ClickAwayListenerProps?: Partial<ClickAwayListenerProps>;
    /**
     * Aria attributes applied to snackbar's content component
     */
    ariaAttributes?: React.AriaAttributes;
    /**
     * If `true`, the `autoHideDuration` timer will expire even if the window is not focused.
     * @default false
     */
    disableWindowBlurListener?: boolean;
    /**
     * The number of milliseconds to wait before dismissing after user interaction.
     * If `autoHideDuration` property isn't specified, it does nothing.
     * If `autoHideDuration` property is specified but `resumeHideDuration` isn't,
     * we use the default value.
     * @default autoHideDuration / 2 ms.
     */
    resumeHideDuration?: number;
    /**
     * The duration for the transition, in milliseconds.
     * You may specify the duration with an object in the following shape:
     * ```js
     * transitionDuration={{ enter: 300, exit: 500 }}
     * ```
     * @default { enter: 225, exit: 195 }
     */
    transitionDuration?: { appear?: number; enter?: number; exit?: number };
}

/** Object interface for snackbars */
interface ISnackbar {
    /** useSnackbar message */
    message: SnackbarMessage;
    /** Modified version of useSnackbar OptionsObject */
    options?: ISnackbarOptions;
}

interface IState {
    /** Snackbars that have yet to be queued */
    toQueue: ISnackbar[];
    /** Snackbars that should be forcibly closed */
    toClose: SnackbarKey[];
}

const initialState: IState = {
    toQueue: [],
    toClose: []
};

const snackbarSlice = createSlice({
    name: 'centralized-snackbar',
    initialState,
    reducers: {
        enqueueSnackbars: (state: IState, action: PayloadAction<ISnackbar[]>) => ({
            ...state,
            toQueue: [
                ...action.payload.map((p, i) => ({
                    message: p.message,
                    options: {
                        ...p.options,
                        key: p.options?.key ?? Date.now() + i
                    }
                })),
                ...state.toQueue
            ]
        }),
        /** Remove queued snackbars */
        enqueuedSnackbars: (state: IState, action: PayloadAction<ISnackbar[]>) => ({
            ...state,
            toQueue: state.toQueue.filter(
                (tq) => !action.payload.map((p) => p.options!.key!).includes(tq.options!.key!)
            )
        }),

        /** Add SnackbarKey to the keys that should be forcibly closed */
        closeSnackbars: (state: IState, action: PayloadAction<SnackbarKey[]>) => ({
            ...state,
            toClose: [...action.payload, ...state.toClose]
        }),
        /** Remove keys */
        closedSnackbars: (state: IState, action: PayloadAction<SnackbarKey[]>) => ({
            ...state,
            toClose: state.toClose.filter((tc) => !action.payload.includes(tc))
        })
    }
});
const actions = snackbarSlice.actions;
export const centralizedSnackbarReducer = snackbarSlice.reducer;

/**
 * Display all snackbars added the the redux queue
 *
 * WARNING: Causes a lot of rerenders
 * */
export const useSnackbarDisplayer = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const qBars = useAppSelector((s) => s.centralizedSnackbar.toQueue);
    useEffect(() => {
        if (qBars.length > 0) {
            for (const bar of qBars) {
                enqueueSnackbar(bar.message, bar.options);
            }
            dispatch(actions.enqueuedSnackbars(qBars));
        }
    }, [dispatch, enqueueSnackbar, qBars]);

    const cKeys = useAppSelector((s) => s.centralizedSnackbar.toClose);
    useEffect(() => {
        if (cKeys.length > 0) {
            for (const key of cKeys) {
                closeSnackbar(key);
            }
            dispatch(actions.closedSnackbars(cKeys));
        }
    }, [cKeys, closeSnackbar, dispatch]);
};

/** useSnackbarDisplayer as a component, so it only rerenders itself */
export const SnackbarDisplayer = () => {
    useSnackbarDisplayer();
    return <></>;
};

interface ISnackbarEnqueuer {
    /** Enqueue a MUI Snackbar */
    enqueueSnackbar(message: ISnackbar['message'], options?: ISnackbar['options']): void;
    /** Enqueue multiple MUI Snackbars */
    enqueueSnackbars(...snackbars: ISnackbar[]): void;
    /** Close a MUI Snackbar */
    closeSnackbar(key: SnackbarKey): void;
    /** Close multiples MUI Snackbars */
    closeSnackbars(...key: SnackbarKey[]): void;
}

/** Hook to enqueue snackbars through redux, calling useSnackbar at the root of the main react tree */
const useCentralizedSnackbar = (): ISnackbarEnqueuer => {
    const dispatch = useAppDispatch();

    /** Enqueue a MUI Snackbar */
    const enqueueSnackbar = (message: ISnackbar['message'], options?: ISnackbar['options']) => {
        const snackbar: ISnackbar = { message, options };
        dispatch(actions.enqueueSnackbars([snackbar]));
    };

    /** Enqueue multiple MUI Snackbars */
    const enqueueSnackbars = (...snackbars: ISnackbar[]) => {
        dispatch(actions.enqueueSnackbars(snackbars));
    };

    /** Close a MUI Snackbar */
    const closeSnackbar = (key: SnackbarKey) => {
        dispatch(actions.closeSnackbars([key]));
    };

    /** Close multiples MUI Snackbars */
    const closeSnackbars = (...keys: SnackbarKey[]) => {
        dispatch(actions.closeSnackbars(keys));
    };

    return {
        enqueueSnackbar,
        enqueueSnackbars,
        closeSnackbar,
        closeSnackbars
    };
};

export default useCentralizedSnackbar;

export const getSnackbar = () => {
    const store = getStore();

    const enqueueSnackbar = (message: ISnackbar['message'], options?: ISnackbar['options']) =>
        store.dispatch({ type: 'centralized-snackbar/enqueueSnackbars', payload: [{ message, options }] });
    const enqueueSnackbars = (...snackbars: ISnackbar[]) =>
        store.dispatch({ type: 'centralized-snackbar/enqueueSnackbars', payload: snackbars });
    const closeSnackbar = (key: SnackbarKey) =>
        store.dispatch({ type: 'centralized-snackbar/closeSnackbars', payload: [key] });
    const closeSnackbars = (...keys: SnackbarKey[]) =>
        store.dispatch({ type: 'centralized-snackbar/closeSnackbars', payload: keys });

    return {
        enqueueSnackbar,
        enqueueSnackbars,
        closeSnackbar,
        closeSnackbars
    };
};
