import 'ol/ol.css';
import 'ol/events/condition';

import { AttributionLike } from 'ol/source/Source';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import FeatureFormat from 'ol/format/Feature';
import { FeatureUrlFunction } from 'ol/featureloader';
import GML32 from 'ol/format/GML32';
import Geometry from 'ol/geom/Geometry';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Polygon from 'ol/geom/Polygon';
import { Vector as VectorSource } from 'ol/source';
import { Options as VectorSourceOptions } from 'ol/source/Vector';
import { bbox } from 'ol/loadingstrategy';

//#region GML 3.2 Parser For OL

export class Gml32Parser {
    //dimension: 2,

    public parseGMLLinearRing(lr: any) {
        const coordList = [];
        let coord = [];
        const coords = lr.posList._content_.split(' ');
        const dim = parseInt(lr.posList.srsDimension);

        for (let i = 0; i < coords.length; i++) {
            const iModDim = i % dim;

            if (iModDim <= 1) {
                coord[iModDim] = parseFloat(coords[i]);
            }
            if (iModDim === 1) {
                coordList.push(coord);
                coord = [];
            }
        }
        //arr.push(coordList);
        return coordList;
        //return new LinearRing(coordList);
    }

    public parseGMLPolygon(polygon: any) {
        const arr: any[] = [];
        const obj = polygon._content_;

        Object.keys(obj).forEach((key) => {
            if (obj[key] instanceof Array) {
                obj[key].forEach((lr: any) => arr.push(this.parseGMLLinearRing(lr.LinearRing)));
            } else if (obj[key] == null) {
                return;
            } else {
                arr.push(this.parseGMLLinearRing(obj[key].LinearRing));
            }
        });
        return new Polygon(arr);
    }

    public parseGMLMultiSurface(multiSurface: any) {
        const multiPolygon = new MultiPolygon([]);
        if (Object.keys(multiSurface._content_).length > 1) {
            console.warn('Something went wrong when trying to parse a GML MultiSurface\n', multiSurface);
        }

        const obj = multiSurface._content_.surfaceMember;

        if (obj instanceof Array) {
            obj.forEach((o) => {
                multiPolygon.appendPolygon(this.parseGMLPolygon(o.Polygon));
            });
        } else if (obj == null) {
            return;
        } else {
            multiPolygon.appendPolygon(this.parseGMLPolygon(obj.Polygon));
        }

        return multiPolygon;
    }

    public parse(obj: any) {
        let result;
        const geoType = Object.keys(obj)[0];
        const geo = obj[geoType];
        switch (geoType) {
            case 'MultiSurface':
                result = this.parseGMLMultiSurface(geo);
                break;
            case 'Polygon':
                result = this.parseGMLPolygon(geo);
                break;
        }
        return result;
    }
}

//#endregion GML 3.2 Parser For OL

export interface WFSSourceOptions {
    attributions?: AttributionLike;
    features?: Feature<Geometry>[] | Collection<Feature<Geometry>>;
    format?: FeatureFormat;
    overlaps?: boolean;
    url?: string | FeatureUrlFunction;
    useSpatialIndex?: boolean;
    wrapX?: boolean;
}

export class WFSSource extends VectorSource<any> {
    private static gml32Parser = new Gml32Parser();

    private dataForsyningWFSLoader(endpoint: string, extent: number[], typename: string, geometryNodeName: string) {
        const url =
            endpoint +
            '&request=GetFeature&service=WFS&version=1.1.0&' +
            'BBOX=' +
            extent.join(',') +
            '&' +
            'typename=' +
            typename +
            '&resultType=results&outputFormat=text/xml; subtype=gml/3.2';

        const oReq = new XMLHttpRequest();
        oReq.addEventListener('load', (_) => {
            const domparser = new DOMParser();
            const xml = domparser.parseFromString(oReq.responseText, 'text/xml');
            const members = xml.getElementsByTagName('wfs:member');
            const tempFeatures = [];
            for (let i = 0; i < members.length; i++) {
                const member = members.item(i)!.children[0];
                if (member != null) {
                    const bygId = member.getAttribute('gml:id');
                    const geo = member.getElementsByTagName(geometryNodeName)[0];
                    if (geo != null) {
                        // geo.firstElementChild.setAttribute('srsName', 'urn:ogc:def:crs:EPSG::25832');
                        // geo.firstElementChild.setAttribute('srsDimension', '3');

                        const gml32 = new GML32();
                        const featureObjFromGml = gml32.readFeatureElementInternal(geo, [], true);
                        const olGeometryObj = WFSSource.gml32Parser.parse(featureObjFromGml);
                        const feature = new Feature(olGeometryObj);

                        feature.setId(bygId as string);
                        feature.setProperties({ xmlData: member }, true);
                        tempFeatures.push(feature);
                    }
                }
            }
            this.addFeatures(tempFeatures);
            //return tempFeatures;
        });
        oReq.open('GET', url);
        oReq.send();
    }

    /**
     *
     */
    constructor(endpoint: string, typename: string, geometryNodeName: string, opt_options?: WFSSourceOptions) {
        const options = (opt_options as VectorSourceOptions) ?? {};
        options.strategy = bbox;
        super(options);
        super.setLoader((extent, resolution, projection) =>
            this.dataForsyningWFSLoader(endpoint, extent, typename, geometryNodeName)
        );
    }
}

//#endregion DataForsyning WFS Loader
