import Content, { IContentFromControlProps } from './content';
import { ILayer, Layers } from '../../LayerMenuControl';
import { ReactElement, useRef } from 'react';
import { Root, createRoot } from 'react-dom/client';

import BaseControl from '../base-control';
import Box from '@mui/material/Box';
import { ControlHandler } from '../../ControlHandler';
import { ControlNames } from '../../../interfaces';
import { DrawEvent } from 'ol/interaction/Draw';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { ICTControl } from '../../CTControl';
import PinLayer from '../../PinLayer';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ReactControl from '../react-control-parent';
import { SaveStatus } from '../../ControlHandlerUtils';
import { Tools } from '../../EditingControl';
import __ from '../../../../../shared/utils/lodash-expansions';
import { staticImplements } from '../../../../../shared/utils/decorators';
import useStateObject from '../../../../../shared/hooks/extended-hooks/use-state-object';

//#region Control

/**
 * A OpenLayers controller for reporting errors to the CT Drift module
 *
 * Overarching map-logic is set up in this controller and then passed down to the underlying react-components,
 * since stuff like working with the controlhandler doesn't work properly in react
 */
@staticImplements<ICTControl>()
export default class ReportDriftErrorControl extends ReactControl {
    // Generelle properties
    public static readonly ControlName = ControlNames.ReportDriftError;
    public static displayName = 'Meldte fejl';
    private static layerKey = `PIN_Meldte fejl`;
    private iLayer: ILayer<PinLayer>;
    private container: HTMLDivElement;
    private reactRoot?: Root;

    /**
     * Constructor for ReportErrorControl
     * @param controlHandler The relevant controlhandler for handling events
     */
    constructor(controlHandler: ControlHandler, iLayer: ILayer<PinLayer>) {
        const container = document.createElement('div');
        super(
            controlHandler,
            {
                layer: iLayer.layer,
                isCluster: true
            },
            {
                element: container
            }
        );
        this.container = container;
        this.iLayer = iLayer;
        this.renderGui();
    }

    private renderGui() {
        if (this.reactRoot === undefined) this.reactRoot = createRoot(this.container);
        this.reactRoot.render(
            <Gui
                controlHandler={this.controlHandler}
                startEdit={this.startEdit.bind(this)}
                stopEdit={this.stopEdit.bind(this)}
                addDrawListener={this.addDrawListener.bind(this)}
                refreshLayer={this.refreshLayer.bind(this)}
                removeLastFeature={this.removeLastFeature.bind(this)}
                removeFeature={this.removeFeature.bind(this)}
                addAddFeatureEventListener={this.addAddFeatureEventListener.bind(this)}
            />
        );
    }

    /** Retrieves and types this controllers relevant ILayer from Layers object */
    public static retrieveILayer(layers: Layers) {
        return layers.getUrlLayers()[this.layerKey] as ILayer<PinLayer>;
    }

    // Nedenstående funktioner (samt dem i reactControl) er defineret her og givet videre til react fordi lag ikke skiftede ordentligt hvis de blev sat fra en react-component
    // Dette har siden vist sig at være på grund af __.copyOmit når props passes videre fra nedenstående Gui til Content, og er altså ikke nødvendigt
    // Strukturen beholdes dog, da det tillader oprettelse af funktioner i reactControl alle senere controllere også kan bruge
    private startEdit() {
        this.controlHandler.activeILayer = this.iLayer;
        this.controlHandler.activeToolType = Tools.Point;
        this.addDrawListener('drawend', this.handleDrawEndEvent.bind(this), { once: true });
    }

    private stopEdit() {
        this.controlHandler.saveStatus = SaveStatus.Cancel; // Trigger code that resets various drawing tools.
    }

    private handleDrawEndEvent(e: DrawEvent) {
        this.stopEdit();

        // Fjern feature fra cluster
        this.iLayer.layer.getSource()?.removeFeature(e.feature);

        // tilføj til cluster-source
        this.addFeature(e.feature);
        this.lastFeature = e.feature;
    }

    private refreshLayer() {
        this.iLayer.layer.getSource()!.getSource()!.refresh();
    }

    private lastFeature?: Feature<Geometry>;
    private removeLastFeature() {
        if (this.lastFeature !== undefined) this.removeFeature(this.lastFeature);
    }
}

const cn = ReportDriftErrorControl.ControlName;

//#endregion Control
//#region Gui shell

type IGuiProps = IContentFromControlProps & {
    controlHandler: ControlHandler;
};

const keysInOnlyGuiProps: string[] = ['controlHandler'];

const Gui = (props: IGuiProps): ReactElement => {
    const open = useStateObject(false);
    const otherRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <BaseControl
                id={`ol-${cn}`}
                name={cn}
                controlHandler={props.controlHandler}
                buttonProps={{
                    // text: 'Fejl'
                    icon: <PriorityHighIcon />
                }}
                openState={open}
            >
                <Content
                    {...__.copyOmit<IContentFromControlProps>(props, ...keysInOnlyGuiProps)}
                    open={open}
                    otherRef={otherRef}
                />
            </BaseControl>
            <Box ref={otherRef} id={`ol-${cn}-other`} />
        </>
    );
};

//#endregion Gui shell
