import { IDictionary, XORA } from '../utils/types';

// Reuse canvas for performance
const canvas = document.createElement('canvas');

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
const internal_getTextWidth = (text: string, font: string) => {
    const context = canvas.getContext('2d');
    if (!context) throw new Error('Could not get canvas context. This should never happen.');

    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
};

export const getCssStyle = (element: HTMLElement, prop: string) => {
    return getComputedStyle(element, null).getPropertyValue(prop);
};

//? These defaults may not be consistent with the most used values in the project
export const getCanvasFont = (el = document.body) => {
    const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
    const fontSize = getCssStyle(el, 'font-size') || '16px';
    const fontFamily = getCssStyle(el, 'font-family') || 'sans-serif';

    return {
        fontWeight,
        fontSize,
        fontFamily
    };
};

type IOptions = XORA<
    [
        {
            font: string;
        },
        {
            fontFamily?: string;
            fontSize?: string;
            fontWeight?: string;
        },
        {
            element: HTMLElement;
        }
    ]
>;

/** Calculates the width of a text string in pixels when displayed in an element with the given options */
const getTextWidth = (text: string, options?: IOptions) => {
    const getFont = () => {
        if (options?.font) return options.font;

        let dictionary: IDictionary<string> = getCanvasFont();
        if (options?.element) dictionary = getCanvasFont(options.element);

        let font = '';
        ['fontFamily', 'fontSize', 'fontWeight'].forEach((key) => {
            const value = (options as IDictionary<string>)?.[key] ?? dictionary[key];
            font += ` ${value}`;
        });

        return font;
    };

    const font = getFont();
    return internal_getTextWidth(text, font);
};

export default getTextWidth;
