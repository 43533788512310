import { IDictionary, XOR, primitive } from '../../../shared/utils/types';

import { IFile } from '../../file-displaying/common';

export interface IAutoFormProps {
    /** The form's input fields and/or groups thereof */
    items: IItem[];

    /** Callback for submitting */
    onSubmit(data: FormData): void | Promise<void>;

    /**
     * A setState function getting called on first form render, giving parents access to form.submit(), handleCancel, and handleHelp
     */
    actionsPosition?: 'header' | 'footer';

    /** Available actions
     * while editing: save | cancel
     * otherwise: edit | back
     */
    actions?: ('submit' | 'cancel' | 'edit' | 'back')[];

    /**
     * Show edit button to switch to edit state
     * @default true
     */
    allowEditing?: boolean;

    /** Submit automatically */
    autoSubmit?: boolean;

    data?: IDictionary;

    /**
     * Edit form initially
     * @default true
     */
    editing?: boolean;

    /** Extra files to display in a filegrid underneath the form */
    files?: IFile[];

    /** Data to append to the form on submit */
    // formData?: Array<IFormData | IFormDataFile>;
    formData?: XOR<IFormData, IFormDataFile>[];

    /** Labellocation */
    labelLocation?: 'left' | 'right' | 'top';

    /** LabelMode */
    labelMode?: 'static' | 'floating' | 'hidden' | 'outside';

    /** Callback for form being canceled */
    onCancel?(): void | Promise<void>;

    /**
     * function to save each time data changes
     * @TODO Better typing
     */
    onFieldDataChanged?: (e: any) => void;
}

export interface IFormData {
    name: string;

    value: string;
}

export interface IFormDataFile {
    filename: string;

    name: string;

    value: Blob;
}

export interface IAutoFormHandle {
    cancel(): void;

    element: HTMLFormElement;

    submit(): void | Promise<void>;
}

/**
 * Enum for auto-form item types
 */
export enum ItemTypes {
    /** No input. Other options still apply though. Can fx be used to create a header */
    NoInput = 'no-input',

    /** A one-line text input */
    String = 'string',
    /** A multi-line text input */
    Block = 'block',
    /** An integer input */
    Number = 'number',
    /** A floating point input */
    Decimal = 'decimal',
    /** A date input */
    Date = 'date',
    /** A radio Button Input */
    Choice = 'choice',
    /** A fileuploader. Doesn't currently have a lot of customization */
    File = 'file',

    /** A conditional input, with 2 radio buttons, and a multi-line text input if the first radio option is chosen */
    Combo_ConditionalBlock = 'combo-conditional-block',

    /** A Combination of radiobuttons and a textfield */
    Combined = 'combined',
    /** lookup or dropdown */
    Lookup = 'lookup',

    /** defalut item relegating most of the detection logic to devextreme */
    Default = 'default'
}

export type IItem = XOR<IInput, IGroup>;

interface ICommonItemProps {
    /** How many columns the item should span */
    colSpan?: number;

    /** Hides the item while editing */
    hideOnEdit?: boolean;

    /** Label for the item */
    label?: string;
}

export interface IGroup extends ICommonItemProps {
    /** Array of items */
    items: IItem[];

    /** Amount of columns in the group */
    colCount?: number;

    /** Amount of columns in the group by screeen width */
    colCountByScreen?: {
        xs?: number;
        sm?: number;
        md?: number;
        lg?: number;
    };

    /** A unique ID, also used as the formitems name */
    id?: string;
}

export interface IInput<T extends TItemValue = TItemValue> extends ICommonItemProps {
    /** A unique ID, also used as the formitems name */
    id: string;

    /**
     * autoComplete prop
     *
     * @default 'off'
     */
    autoComplete?: string;

    choices?: string | XOR<string[], { id: string; value: string }[]>;

    /** Options passed down to the relevant dx editor */
    editorOptions?: {
        maxHeight?: string;
        minHeight?: string;
        mask?: string;
        format?: string;
        [key: string]: any;
    };

    /** Editortype. Overrides the type prop */
    editorType?:
        | 'dxAutocomplete'
        | 'dxCalendar'
        | 'dxCheckBox'
        | 'dxColorBox'
        | 'dxDateBox'
        | 'dxDropDownBox'
        | 'dxHtmlEditor'
        | 'dxLookup'
        | 'dxNumberBox'
        | 'dxRadioGroup'
        | 'dxRangeSlider'
        | 'dxSelectBox'
        | 'dxSlider'
        | 'dxSwitch'
        | 'dxTagBox'
        | 'dxTextArea'
        | 'dxTextBox';

    /** Specifies the help text displayed for the current form item. */
    helpText?: string;

    /** Mask / format for input */
    mask?: string;

    /** Mazimum amount of characters in the field */
    maxLength?: number;

    /** Placeholder when nothing is typed */
    placeholder?: T;

    /**
     * Is item readonly?
     *
     * @default false
     */
    readOnly?: boolean;

    /**
     * Is item required?
     *
     * @default true
     */
    required?: boolean;

    /** Which input to display */
    type?: ItemTypes;
}

export interface IActionsProps {
    actions: NonNullable<IAutoFormProps['actions']>;

    allowEditing: boolean;

    isEditing: boolean;

    triggerBack: () => void;

    triggerCancel: () => void;

    triggerEdit: () => void;

    triggerSubmit: () => void;
}

export interface IComboConditionValue {
    choice: string | number;

    text: string;
}
type TItemValue = primitive | IComboConditionValue;
