// import 'devextreme-intl';

import { loadMessages, locale } from 'devextreme/localization';

import daMessages from './da.json';

//import daAsDeMessages from "./da-as-de.json";

//import deMessages from "devextreme/localization/messages/de.json";

const Localize = () => {
    loadMessages(daMessages);
    //loadMessages(daAsDeMessages);
    //loadMessages(deMessages);
    //locale(navigator.language);
    locale('da');
};

export default Localize;
