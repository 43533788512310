/* eslint-disable no-restricted-imports */
import version from './config.json';

// Do not change these values, use a .env file instead. An example file has been supplied.

const base = new URL(process.env.REACT_APP_SERVER_URL_BASE ?? 'https://devapi.caretaker.dk');
const noTopDomain = /(.*)\./.exec(base.hostname)?.[1];

export const NAME = noTopDomain;

export const VERSION = version.VERSION;
export const BUILD = version.BUILD;
export const BUILD_TIME = version.BUILD_TIME;
export const FULL_VERSION = `${VERSION}.${BUILD}`;

export const SERVER_URL_BASE = base.toString();
export const SERVER_URL = base.toString() + 'api';
export const NODE_TEST_API = process.env.REACT_APP_NODE_TEST_API ?? 'https://reactnode.caretaker.dk';

export const MSAL_INSTANCE = process.env.REACT_APP_MSAL_INSTANCE ?? 'https://login.microsoftonline.com';
export const MSAL_REDIRECT =
    process.env.REACT_APP_MSAL_REDIRECT ?? 'https://devapi.caretaker.dk/api/User/ValidateMicrosoft';
export const MSAL_CLIENT_ID = process.env.REACT_APP_MSAL_CLIENT_ID ?? '03d0cec3-56bd-471c-9b6c-53857de616eb';
export const MSAL_TENANT_ID = process.env.REACT_APP_MSAL_TENANT_ID ?? '41c4ec4f-8b55-4804-bd32-e7e14cdf1288';

const config = {
    NAME,

    VERSION,
    BUILD,
    BUILD_TIME,
    FULL_VERSION,

    SERVER_URL_BASE,
    SERVER_URL,
    NODE_TEST_API,

    MSAL_INSTANCE,
    MSAL_REDIRECT,
    MSAL_CLIENT_ID,
    MSAL_TENANT_ID
} as const;

export default config;
