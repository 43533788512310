import Grow, { GrowProps } from '@mui/material/Grow';
import Paper, { PaperProps } from '@mui/material/Paper';
import { ReactNode, useRef } from 'react';
import __, { useDeepMerge } from '../../../../shared/utils/lodash-expansions';
import { contentLeft, contentRight } from '../react-controls/base-control';

import AnimationHelper from '../AnimationHelper';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import useOnOutsideClick from '../../../../shared/hooks/event-hooks/use-on-outside-click';

//type Ttimeout

type IProps = PaperProps & NonPaperProps;

interface NonPaperProps {
    /** Function to close the component when a click is detected outside,  */
    onOutsideClick?(): void;

    /** If true, the component will transition in */
    in?: boolean;
    /**
     * The duration for the transition, in milliseconds. You may specify a single timeout for all transitions, or individually with an object.
     *
     * Set to 'auto' to automatically calculate transition time based on height.
     *
     * @default AnimationHelper.time
     */
    timeout?: GrowProps['timeout'];

    children?: ReactNode;
}

const nonPaperKeys = ['closeOnOutsideClick', 'in', 'timeout', 'children'];

const defaultProps: Omit<IProps, 'children'> = {
    elevation: 3,
    sx: {
        padding: '5px 8px 5px 8px',
        borderRadius: '10px'
    },
    in: true,
    timeout: AnimationHelper.time
};

const boxSx: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    // top: '7px',
    // width: contentMaxWidth,
    marginLeft: `calc(${contentLeft} + 5px)`,
    marginRight: `calc(${contentRight} + 5px)`
};

const PaperPopover = ({ children, ...initialProps }: IProps) => {
    const props: Omit<IProps, 'children'> = useDeepMerge(defaultProps, initialProps);
    const paperRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = () => props.onOutsideClick?.();
    useOnOutsideClick(paperRef, handleOutsideClick);

    return (
        <Box sx={boxSx}>
            <Grow in={props.in} timeout={props.timeout} mountOnEnter unmountOnExit>
                <Paper ref={paperRef} {...__.copyOmit(props, ...nonPaperKeys)}>
                    {children}
                </Paper>
            </Grow>
        </Box>
    );
};

export default PaperPopover;
