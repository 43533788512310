import { Fragment, useState } from 'react';

import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ICreateGui } from './create-gui';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const CreateBaseLayerGui = ({ layers, controlHandler }: ICreateGui) => {
    const [value, setValue] = useState(layers.defaultMainLayer);
    const [open, setOpen] = useState(false);
    const baseLayers = layers.getBaseLayers();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, val: string) => {
        e.stopPropagation();
        setValue(val);
        controlHandler.activeBaseILayerKey = val ?? '';
        baseLayers[val].layer.setVisible(true);
        Object.keys(baseLayers).forEach((key) => {
            if (key !== val) {
                baseLayers[key].layer.setVisible(false);
            }
        });
    };

    const onOpenClick = () => {
        setOpen((prev) => !prev);
    };

    return (
        <>
            <Stack key={`stack-baggrundskort`} direction={'row'} justifyContent={'space-between'} onClick={onOpenClick}>
                <Typography variant='h6'>Baggrundskort</Typography>
                <IconButton size='small' sx={{ padding: 0 }}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </Stack>
            <Collapse in={open} className='layer-options-collapse'>
                <FormControl sx={{ pb: '4px' }}>
                    <RadioGroup value={value} onChange={onChange}>
                        {Object.keys(baseLayers).map((l) => {
                            const name = baseLayers[l].displayName == null ? l : baseLayers[l].displayName;
                            return (
                                <FormControlLabel
                                    id={l}
                                    key={l}
                                    value={l}
                                    label={<Typography sx={{ margin: 0 }}>{name}</Typography>}
                                    control={
                                        <Radio
                                            size='small'
                                            sx={{
                                                padding: 0,
                                                paddingLeft: '10px',
                                                paddingRight: '4px',
                                                paddingTop: '1.5px'
                                            }}
                                        />
                                    }
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
            </Collapse>
        </>
    );
};

export default CreateBaseLayerGui;
