import { RefObject, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { addAddFeatureEventListener, addDrawListener, removeFeature } from '../react-control-parent';

import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button';
import Feature from 'ol/Feature';
import { FlexRow } from '../../../../misc/flex';
import Geometry from 'ol/geom/Geometry';
import GetHelpdeskCreateRender from './helpdesk-create-render';
import { IStateObject } from '../../../../../shared/hooks/extended-hooks/use-state-object';
import PaperPopover from '../../react-components/paper-popover';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Popup from 'devextreme-react/popup';
import Portal from '@mui/material/Portal';
import Typography from '@mui/material/Typography';
import { isMobile } from 'react-device-detect';
import { toWkt } from '../../WktIEControl';
import { useMapSetup } from '../../../redux-slice';

type IContentProps = IContentFromControlProps & IContentFromGuiProps;

export interface IContentFromControlProps {
    startEdit(): void;
    stopEdit(): void;
    addDrawListener: addDrawListener;
    removeLastFeature(): void;
    removeFeature: removeFeature;
    addAddFeatureEventListener: addAddFeatureEventListener;
    refreshLayer(): void;
}

export interface IContentFromGuiProps {
    open: IStateObject<boolean>;
    otherRef: RefObject<HTMLDivElement>;
}

/**
 * The visual content for the ReportDriftErrorControl
 *
 * This can easily be optimized by moving the popover into its own component, as most of it's logic work isolated aswell
 */
const Content = (props: IContentProps) => {
    const { setup } = useMapSetup();
    const mProps = useRef(props);
    const [placing, setPlacing] = useState(false);
    const [feature, setFeature] = useState<Feature<Geometry>>();
    const [errorNumber, setErrorNumber] = useState(0);
    const wkt = useMemo(() => (feature === undefined ? '' : toWkt(feature)), [feature]);

    const closePopup = () => {
        setPlacing(false);
        setFeature(undefined);
    };

    // Flytning af dette fra handlePlaceClicked gav langt bedre performance.
    // Det tager åbenbart tid, så det skal ligge et sted det ikke blokker
    useLayoutEffect(() => {
        mProps.current.addDrawListener(
            'drawend',
            (e) => {
                setFeature(e.feature);
                setPlacing(false);
            },
            { once: true }
        );
    }, [placing]);

    const handlePlaceClicked = useCallback(() => {
        setPlacing(true);
        mProps.current.startEdit();
        mProps.current.open.setState(false);
    }, []);

    // Edit from the get go if this control is opened through redux
    const isSetupPlacing = setup.controls['report-drift-error-control'].isPlacing;
    useLayoutEffect(() => {
        if (isSetupPlacing) handlePlaceClicked();
    }, [handlePlaceClicked, isSetupPlacing]);

    const handleCancelClicked = () => {
        props.stopEdit();
        setPlacing(false);
    };

    /** Reload features to add functionality to the newly added feature */
    const handlePopupSubmitted = () => {
        props.refreshLayer();
    };

    /** Remove the placed feature when canceled */
    const handlePopupCanceled = () => {
        props.removeLastFeature();
    };

    /** Change popup key after closing to fully reset it */
    const handlePopupHidden = () => {
        setErrorNumber((e) => e + 1);
    };

    return (
        <>
            <Box style={{ margin: '0.2em' }}>
                <Box style={{ marginBottom: '0.5em' }}>
                    <Typography align='center' variant='h5'>
                        Helpdesk
                    </Typography>
                </Box>
                <Box>
                    <Button onClick={handlePlaceClicked} variant='outlined' endIcon={<PinDropIcon />}>
                        Placér
                    </Button>
                </Box>
            </Box>

            {/* Other, shown outside BaseControlContent */}
            <Portal container={props.otherRef.current}>
                <PaperPopover in={placing}>
                    <FlexRow
                        right={
                            <Button onClick={handleCancelClicked} variant='outlined' sx={{ height: '100%', ml: 2 }}>
                                Annuller
                            </Button>
                        }
                    >
                        <Typography align='center'>Tryk på kortet for at placere fejlen</Typography>
                    </FlexRow>
                </PaperPopover>
            </Portal>

            {/* Popup til at rapportere fejlen */}
            {/* Er åben hvis wkt er sat, og både placing og confirming er false, da wkt bliver undefined hvis der ikke confirmes */}
            {/* Det sker altså kun hvis der er blevet bekræftet, og vi undgår dermed et ekstra "open" state */}

            <Popup
                key={`err_rep_popup_${errorNumber}`}
                visible={feature !== undefined && placing === false}
                fullScreen={isMobile}
                contentRender={() =>
                    GetHelpdeskCreateRender({
                        closePopup,
                        onSubmitted: handlePopupSubmitted,
                        onCanceled: handlePopupCanceled,
                        formData: [
                            {
                                name: 'geometry',
                                value: wkt
                            }
                        ]
                    })
                }
                showTitle={false}
                showCloseButton={false}
                onHidden={handlePopupHidden}
                hideOnOutsideClick={true}
            />
        </>
    );
};

//#endregion Content

export default Content;
