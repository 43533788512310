/* eslint-disable @typescript-eslint/ban-ts-comment */
//#region cm Settings handling

import { ProjectionLike } from 'ol/proj';
import config from '../../../config';

export interface Authentication {
    user: string;
    pass: string;
}

export interface ICaretakerMapSettings {
    initMap: boolean;
    auth: Authentication;
    startZoomLevel: number;
    layerImgPath: string;
    markerImgPath: string;
    markerCoords: [number, number];
    markerScaleOnMobile: number;
    showMarker: boolean;
    projection: ProjectionLike;
    iconFolderPath: string;
    endpointGetFeatures: string;
    endpointPostFeature: string;
    endpointGetEnhSysFromId: string;
    // Til brug med rottespærekort
    saveCoords: {
        proj: ProjectionLike; //EPSG:4326
        //Denne delegate bliver kørt når markeren er blevet flyttet
        func(lat: number, lon: number): void;
    };
}

export class CaretakerMapSettings {
    private static CaretakerMapDefaultSettings: ICaretakerMapSettings = {
        initMap: false,
        auth: {
            user: 'CPTOHNMYGD',
            pass: '3ncW9c8_tMM4uhj'
        },
        startZoomLevel: 3.2,
        layerImgPath: './content/layers.png',
        markerImgPath: './content/marker.png',
        markerCoords: [596392.4792498973, 6224105.620663913],
        markerScaleOnMobile: 2,
        showMarker: true,
        projection: 'EPSG:25832',
        iconFolderPath: '/images/map-icons/',
        endpointGetFeatures: config.SERVER_URL + '/Map/GetFeatures',
        endpointPostFeature: config.SERVER_URL + '/Map/PostFeatures',
        endpointGetEnhSysFromId: '/Utils/GetEnhSysFromId/',
        // Til brug med rottespærekort
        saveCoords: {
            proj: 'EPSG:25832', //EPSG:4326
            //Denne delegate bliver kørt når markeren er blevet flyttet
            func: (lat, lon) => undefined
        }
    };

    private static settingsIsSet = false;

    public static get(): ICaretakerMapSettings {
        if (!CaretakerMapSettings.settingsIsSet) {
            // @ts-ignore
            if (typeof window['cm'] === 'undefined')
                // @ts-ignore
                window['cm'] = {};
            // @ts-ignore
            if (typeof window['cm']['settings'] === 'undefined')
                // @ts-ignore
                window['cm']['settings'] = CaretakerMapSettings.CaretakerMapDefaultSettings;
            else {
                //if a setting is not already is defined or null, i will be set to the value of the defaultsetting.
                // @ts-ignore
                const cmsKeys = Object.keys(window['cm']['settings']);
                Object.keys(CaretakerMapSettings.CaretakerMapDefaultSettings).forEach((key) => {
                    // @ts-ignore
                    if (!cmsKeys.includes(key) || window['cm']['settings'][key] == null)
                        // @ts-ignore
                        window['cm']['settings'][key] = CaretakerMapSettings.CaretakerMapDefaultSettings[key];
                });
            }
            CaretakerMapSettings.settingsIsSet = true;
        }
        // @ts-ignore
        return window['cm']['settings'];
    }

    public static set(settingName: string, value: unknown) {
        // @ts-ignore
        window['cm']['settings'][settingName] = value;
    }
}
export const cmSettings = CaretakerMapSettings.get();

//export let CaretakerMapSettings = window['cm'];
//var auth = cm.settings.auth;

//#endregion cm Settings handling
