import { useAppDispatch, useAppSelector } from '../redux-base-hooks';
import useQRScannerSlice, { callbackStore } from './slice';

import Popup from 'devextreme-react/popup';
import { QrScanner } from '@yudiel/react-qr-scanner';
import styles from './scanners.module.css';

const Scanner = ({ scannerKey }: { scannerKey: string }) => {
    const dispatch = useAppDispatch();
    const scanner = useAppSelector((state) => state.useQRScanner.find((s) => s.key === scannerKey))!;
    return (
        <Popup
            visible={scanner.active}
            onHiding={() => dispatch(useQRScannerSlice.actions.stop({ key: scannerKey }))}
            showCloseButton
            wrapperAttr={{ class: styles['fix-popup-content-size'] }}
            {...scanner.PopupProps}
            title='Scan QR kode'
        >
            <QrScanner {...scanner.QRProps} {...callbackStore[scannerKey]} />
        </Popup>
    );
};

const Scanners = () => {
    const scanners = useAppSelector((state) => state.useQRScanner.map((s) => s.key));

    return (
        <>
            {scanners.map((k) => (
                <Scanner key={k} scannerKey={k} />
            ))}
        </>
    );
};

export default Scanners;
