import CTControl, { ICTControl } from './CTControl';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { ControlEventTarget, ExtendedEvent } from './ControlHandlerUtils';

import AnimationHelper from './AnimationHelper';
import { ControlHandler } from './ControlHandler';
import { ControlNames } from '../interfaces';
import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation';
import Point from 'ol/geom/Point';
import { StaticCookieHandler } from './CookieHandler';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { isMobile } from 'react-device-detect';
import { staticImplements } from '../../../shared/utils/decorators';

// Inspiration fra ol eksempel:
// https://openlayers.org/en/latest/examples/geolocation.html

@staticImplements<ICTControl>()
export class GPSControl extends CTControl {
    // Generelle properties
    private isVisible!: boolean;
    private geolocation: Geolocation;
    private showAccuracy: boolean;
    private animationHelper: AnimationHelper;

    public static centerOnUser: boolean;

    private gui: {
        div: HTMLDivElement;
        menu: HTMLDivElement;
        btnBackground: HTMLDivElement;
        menuHeader1: HTMLDivElement;
        trackInput: HTMLInputElement;
        accInput: HTMLInputElement;
        btnFindMe: HTMLDivElement;
    };

    // Features
    private accuracyFeature!: Feature;
    private positionFeature!: Feature;

    // Statics
    public static ControlName = ControlNames.GPS;

    constructor(controlHandler: ControlHandler, layer: VectorLayer<VectorSource>) {
        const gui = GPSControl.createGuiElement();
        super(controlHandler, {
            element: gui.div
        });

        this.animationHelper = new AnimationHelper();
        this.gui = gui;
        gui.div.style.top = `${this.controlHandler.getAssignedHeight()}px`;
        this.geolocation = new Geolocation({ trackingOptions: { enableHighAccuracy: true }, projection: 'EPSG:25832' });
        this.showAccuracy = true;

        this.createFeatures();
        this.addEventListeners();

        layer.setSource(new VectorSource({ features: [this.accuracyFeature, this.positionFeature] }));
        layer.setVisible(true);

        this.geolocation.setTracking(true);

        if (GPSControl.centerOnUser) this.center();
    }

    public static async getPosition() {
        const geo = new Geolocation({ trackingOptions: { enableHighAccuracy: true }, projection: 'EPSG:25832' });
        geo.setTracking(true);

        return await new Promise<null | number[]>((resolve) => {
            setTimeout(() => resolve(null), 1000);
            geo.on('error', () => resolve(null));
            geo.on('change:position', () => resolve(geo.getPosition() ?? null));
        });
    }

    private createFeatures() {
        // Lav features og stil

        this.accuracyFeature = new Feature();
        this.positionFeature = new Feature();

        this.accuracyFeature.set('visible', true);
        this.positionFeature.set('visible', true);

        this.positionFeature.setStyle(
            new Style({
                image: new CircleStyle({
                    radius: 6,
                    fill: new Fill({
                        color: '#3399CC'
                    }),
                    stroke: new Stroke({
                        color: '#fff',
                        width: 2
                    })
                })
            })
        );
    }

    private addEventListeners() {
        // Error, som f.eks. at brugeren ikke accepterer gps-adgang. Alt geolocation-relateret kode stopper på denne error
        this.geolocation.on('error', (error) => {
            //if (error.message.toLowerCase().includes('User denied'.toLowerCase())){
            this.geolocation.setTracking(false);

            this.gui.accInput.checked = false;
            this.gui.accInput.checked = false;

            this.showHideFeature(this.positionFeature, false);
            this.showHideFeature(this.accuracyFeature, false);

            this.gui.accInput.disabled = true;

            console.error(error);
            //}
        });

        // Præcision har ændret sig
        this.geolocation.on('change:accuracyGeometry', (event) => {
            this.accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry() ?? undefined);
        });

        // Position har ændret sig
        this.geolocation.on('change:position', (event) => {
            const coordinates = this.geolocation.getPosition();
            this.positionFeature.setGeometry(coordinates ? new Point(coordinates) : undefined);
        });

        //  Control klikkes, hvis/skjul
        this.gui.btnBackground.addEventListener('click', () => {
            this.changeVisibility();
        });

        this.gui.btnFindMe.addEventListener('click', () => {
            this.center();
        });

        // Skjul når anden controller åbnes
        this.controlHandler.addCustomEventListener('change', (e: ExtendedEvent) => {
            if (
                e.eventTarget === ControlEventTarget.ActiveController &&
                this.isVisible &&
                e.value !== GPSControl.ControlName
            ) {
                this.changeVisibility();
            }
        });

        // Checkboxe
        // Toggle position
        this.gui.trackInput.addEventListener('click', () => {
            this.geolocation.setTracking(this.gui.trackInput.checked);

            this.showHideFeature(this.positionFeature, this.gui.trackInput.checked);
            this.showHideFeature(this.accuracyFeature, this.gui.trackInput.checked ? this.showAccuracy : false);

            this.gui.accInput.disabled = !this.gui.trackInput.checked;
        });

        // Toggle præcision
        this.gui.accInput.addEventListener('click', () => {
            this.showHideFeature(this.accuracyFeature, this.gui.accInput.checked);
            this.showAccuracy = this.gui.accInput.checked;
        });
    }

    private async center() {
        let attempts = 0;

        const tryCenter = () => {
            const coords = this.geolocation.getPosition();
            // console.log('attempt: ', attempts, '\ncoords: ', coords);
            if (coords !== undefined) {
                StaticCookieHandler.centerAtCoords(coords, undefined, 1500);
            } else if (++attempts < 5) {
                setTimeout(tryCenter, 100);
            } else {
                this.controlHandler.GPSCenterError = true;
            }
        };

        await new Promise<void>((resolve) => {
            tryCenter();
            resolve();
        });
    }

    // https://gis.stackexchange.com/questions/270387/show-hide-features-on-layer-in-openlayers
    private showHideFeature(feature: Feature, newVisibility: boolean) {
        // if the visibility we are trying to set is the same, do nothing
        if (feature.get('visible') === newVisibility) {
            return;
        }
        // otherwise, change the style of the feature,
        //and save the old style as a property so we can restore it later
        if (newVisibility === true) {
            const featureSavedStyle = feature.get('savedStyle');
            feature.setStyle(featureSavedStyle);
        } else {
            feature.set('savedStyle', feature.getStyle());
            feature.setStyle(new Style(undefined)); // this is actually hiding the feature
        }
        feature.set('visible', newVisibility);
    }

    private changeVisibility() {
        this.isVisible = !this.isVisible;
        if (this.isVisible) {
            //this.gui.menu.classList.add('ol-buildingControl-options-open');
            this.controlHandler.activeController = GPSControl.ControlName;
            this.animationHelper.show(this.gui.menu);
        } else {
            //this.gui.menu.classList.remove('ol-buildingControl-options-open');
            this.animationHelper.hide(this.gui.menu);
        }
    }

    private static createGuiElement() {
        // overordnet box
        const div: HTMLDivElement = document.createElement('div');
        div.id = 'ol-buildingControl';
        div.className = 'ol-unselectable ol-buildingControl ol-control-flex-left'; //  ol-control
        div.style.top = '203px';
        div.style.pointerEvents = 'none';

        // Menu der kommer op
        const menu = document.createElement('div');
        menu.className = 'ol-buildingControl-options ol-replicate-mui-paper ol-replicate-mui-grow-hidden';

        // Menu overskrift.
        const menuHeader1 = document.createElement('h1');
        menuHeader1.innerText = 'Lokationsmenu';
        menuHeader1.className = 'buildingId';
        menu.appendChild(menuHeader1);

        //#region Toggles
        // css stjålet fra LayerMenuControl

        const toggleDiv: HTMLDivElement = document.createElement('div');

        //#region Track?
        const trackDiv = document.createElement('div');
        const trackInput = document.createElement('input');
        const trackLabel = document.createElement('label');

        trackInput.id = 'trackToggler';
        trackInput.name = 'trackToggler';
        trackInput.type = 'checkbox';
        trackInput.classList.add('ol-layerControl-input');
        trackInput.checked = true;
        trackDiv.appendChild(trackInput);

        trackLabel.innerHTML = 'Vis position';
        trackLabel.htmlFor = trackInput.id;
        trackDiv.appendChild(trackLabel);

        trackDiv.classList.add('ol-layerControl-inputDiv');
        toggleDiv.appendChild(trackDiv);

        //#endregion

        //#region Accuracy?

        const accDiv = document.createElement('div');
        const accInput = document.createElement('input');
        const accLabel = document.createElement('label');

        accInput.id = 'accToggler';
        accInput.name = 'trackingToggler';
        accInput.type = 'checkbox';
        accInput.classList.add('ol-layerControl-input');
        accInput.checked = true;
        accDiv.appendChild(accInput);

        accLabel.innerHTML = 'Vis præcision';
        accLabel.htmlFor = accInput.id;
        accDiv.appendChild(accLabel);

        accDiv.classList.add('ol-layerControl-inputDiv');
        toggleDiv.appendChild(trackDiv);
        toggleDiv.appendChild(accDiv);
        //#endregion

        menu.appendChild(toggleDiv);

        //#endregion

        const disclaimer: HTMLParagraphElement = document.createElement('p');
        disclaimer.innerHTML = `Caretaker modtager aldrig data om din lokation, det bruges udelukkende til at vise dig hvor du er.<br>
                                Virker lokationen ikke, kan det hjælpe at genindlæse siden. Det skyldes højest sandsynligt at din browser har nægtet adgang til din position`;
        menu.appendChild(disclaimer);

        // Forbind med
        div.appendChild(menu);

        // Knap i liste af controls
        const btnBackground = document.createElement('div');
        btnBackground.className = 'ol-buildingControl-btnBackground ol-btn-background-height';

        const btn = document.createElement('div');
        btn.className = 'ol-buildingControl-btn';
        // btn.className = 'ol-layerControl-btn'
        btn.innerText = 'GPS';
        btn.style.cursor = 'pointer';
        btnBackground.appendChild(btn);
        div.appendChild(btnBackground);

        const btnFindMe = document.createElement('div');
        const classString = isMobile ? 'ol-buildingControl-btnFindMe' : 'ol-buildingControl-btnFindMePC';
        btnFindMe.className = `ol-buildingControl-btn ${classString}`;
        // btnFindMe.innerText = 'Mig';
        btnFindMe.style.cursor = 'pointer';

        const img = document.createElement('img');
        img.src = '/Images/map-icons/my-location.svg';
        img.style.verticalAlign = 'top';

        const imgDiv = document.createElement('div');
        imgDiv.style.width = '80%';
        imgDiv.style.margin = 'auto';
        imgDiv.style.paddingTop = '10%';
        imgDiv.appendChild(img);
        btnFindMe.appendChild(imgDiv);

        document.querySelector('.ol-zoom.ol-unselectable.ol-control')?.appendChild(btnFindMe);

        return {
            div: div,
            menu: menu,
            btnBackground: btnBackground,
            menuHeader1: menuHeader1,
            trackInput: trackInput,
            accInput: accInput,
            btnFindMe: btnFindMe
        };
    }
}
