import { IDictionary, PropsOf } from '../../../../shared/utils/types';
import { IInput, ItemTypes } from '../interfaces';

import FileUploader from 'devextreme-react/file-uploader';
import ImageFileGrid from '../../../file-displaying/image-file-grid';
import { SimpleItem } from 'devextreme-react/form';
import Typography from '@mui/material/Typography';
import { defaultIInputs } from '../defaults';
import { generateProps } from './generate';
import styles from './input.module.css';
import useTContext from '../../../../shared/contexts/t-context';

const InputFile = (input: IInput) => {
    // if (input.readOnly)
    //     return null;

    // let label = input.label;
    // if (label) label += ":";

    return (
        <SimpleItem
            {...generateProps(input, defaultIInputs[ItemTypes.File])}
            cssClass={styles['item-input-file']}
            render={(item) => getRender({ item, input })}
            // render={console.log as any}
        >
            {/* {input.label && <Typography align="left">{label}</Typography>}
            <Typography align="left" display="block" variant="caption" color="gray">{"Uploadede filer kan først fjernes senere"}</Typography>
            <FileUploader name={input.id} multiple labelText="" uploadMode="useForm" /> */}
        </SimpleItem>
    );
};

const getRender = (props: { item: PropsOf<SimpleItem>; input: IInput }) => <Render {...props} />;

const Render = ({ item, input }: { item: PropsOf<SimpleItem>; input: IInput }) => {
    const { value: data } = useTContext<IDictionary>();

    return (
        <>
            {input.label && <Typography align='left'>{input.label}</Typography>}
            {!input.readOnly && (
                <>
                    <Typography align='left' display='block' variant='caption' color='gray'>
                        {'Uploadede filer kan først fjernes senere'}
                    </Typography>
                    <FileUploader name={input.id} multiple labelText='' uploadMode='useForm' />
                </>
            )}
            <ImageFileGrid files={data?.[input.id] ?? []} />
        </>
    );
};

export default InputFile;
