import CreateLayerIcon, { TextSVG } from './create-layer-icon';
import { ILayers, Layers } from '../../LayerMenuControl';
import { Tools, shapeTypes } from '../../EditingControl';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import useTContext, { TProvider } from '../../../../../shared/contexts/t-context';

import Api from '../../../../../shared/networking/api';
import { BaseIconId } from '../../SVG';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { BygLayer } from '../../deprecated/BygLayer';
import CTLayer from '../../CTLayer';
import CancelIcon from '@mui/icons-material/Cancel';
import Checkbox from '@mui/material/Checkbox';
import Circle from 'ol/geom/Circle';
import Collapse from '@mui/material/Collapse';
import { ControlHandler } from '../../ControlHandler';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { EnhLayer } from '../../deprecated/EnhLayer';
import Feature from 'ol/Feature';
import { FeatureProperty } from '../../feature-utility';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { ICreateGui } from './create-gui';
import { IDictionary } from '../../../../../shared/utils/types';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Point from 'ol/geom/Point';
import SaveIcon from '@mui/icons-material/Save';
import { SaveStatus } from '../../ControlHandlerUtils';
import { SqlLayer } from '../../deprecated/SqlLayer';
import Stack from '@mui/material/Stack';
import { Style } from 'ol/style';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { polyLayerStyle } from '../..';
import { toWkt } from '../../WktIEControl';
import { useCTEffect } from '../../../../../shared/hooks/use-ct';
import useShiftRooms from './use-shift-rooms.hook';

enum Modes {
    Edit = 'edit',
    Delete = 'delete',
    Select = 'select',
    Cancel = 'cancel',
    Save = 'save'
}

enum States {
    Closed,
    Open,
    Editing
}

interface IRef {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setChecked: React.Dispatch<React.SetStateAction<boolean>>;
    setShowArrow: React.Dispatch<React.SetStateAction<boolean>>;
    setEditData: React.Dispatch<React.SetStateAction<{ saveArray: IDictionary[]; movedFeatures: Feature[]; }>>;
}

// Just to make sure that old things still work. The new stuff only uses the shapetype
const getLayerSvg = ({ layers, selectedKey }: { layers: Layers; selectedKey: string; }) => {
    const urlLayers = layers.getUrlLayers();
    const shapeType: string | undefined = (urlLayers[selectedKey].layer as CTLayer).options?.shapeType;
    const color = (urlLayers[selectedKey].layer as CTLayer).options.color ?? '';
    if (selectedKey.toLowerCase().startsWith('del'.toLowerCase()))
        return CreateLayerIcon(
            (urlLayers[selectedKey].layer as SqlLayer).baseIconId,
            (urlLayers[selectedKey].layer as SqlLayer).color
        );
    else if (selectedKey.toLowerCase().startsWith('byg'.toLowerCase()))
        return CreateLayerIcon(
            (urlLayers[selectedKey].layer as BygLayer).baseIconID,
            (urlLayers[selectedKey].layer as BygLayer).color
        );
    else if (selectedKey.toLowerCase().startsWith('enh'.toLowerCase()))
        return CreateLayerIcon(
            (urlLayers[selectedKey].layer as EnhLayer).baseIconID,
            (urlLayers[selectedKey].layer as EnhLayer).color
        );
    else if (shapeType === shapeTypes.Point || shapeType === shapeTypes.Line || shapeType === shapeTypes.Polygon)
        return CreateLayerIcon(shapeType, color);
    else {
        return CreateLayerIcon(BaseIconId.Default, '#56aaff');
    }
};

const saveData = async (
    saveArray: IDictionary[],
    movedFeatures: Feature[],
    selectedLayer: CTLayer,
    controlHandler: ControlHandler,
    floor: string
) => {
    const url = selectedLayer.options.url;
    if (!url) return;

    let success = true;
    if (saveArray.length > 0) {
        const response = await Api.post(url, saveArray);
        success = success && Api.ok(response);
    }

    const moved = movedFeatures.map((f) => {
        const data = f.get(FeatureProperty.Data);
        const geo = toWkt(f);
        return {
            ...data,
            geometry: geo
        };
    });
    if (moved.length > 0) {
        const response = await Api.put(url, moved);
        success = success && Api.ok(response);
    }
    if (!success) {
        alert('Der skete en fejl');
    }
    controlHandler.saveStatus = SaveStatus.Start;
    //#endregion
};

// Forwardref to be able to fx close the old menu, when a new one is opened
// Memo to avoid rerendering
const CreateLayerEditMenu = forwardRef<
    IRef,
    ICreateGui & { selectedKey: string; setCurr: (obj: { index: string; state: States; }) => void; floor: string; }
>(({ layers, controlHandler, selectedKey, setCurr, floor }, ref) => {
    const stackRef = useRef();
    const [open, setOpen] = useState(false);
    const [showArrow, setShowArrow] = useState(true);

    // editdata gets data, from the setEditData function exported below, which is triggered
    // in EditingControl.ts whenever a new feature is placed or a feature is moved or clicked
    // This array gives is used to give label to everything
    const [editData, setEditData] = useState<{ saveArray: IDictionary[]; movedFeatures: Feature[]; }>({
        saveArray: [],
        movedFeatures: []
    });
    const urlLayers: ILayers<CTLayer> = layers.getUrlLayers();
    const { value: mode, setValue: setMode } = useTContext<Modes>();
    const [checked, setChecked] = useState(urlLayers[selectedKey].layer.getVisible());
    const name = urlLayers[selectedKey].displayName == null ? selectedKey : urlLayers[selectedKey].displayName;

    useImperativeHandle(ref, () => ({
        setOpen: setOpen,
        setChecked: setChecked,
        setShowArrow: setShowArrow,
        setEditData: setEditData
    }));

    useCTEffect(() => {
        if (controlHandler.saveStatus === SaveStatus.Labels) {
            console.log('saving');
            saveData(
                editData.saveArray,
                editData.movedFeatures,
                urlLayers[selectedKey].layer as CTLayer,
                controlHandler,
                floor
            );
            setEditData({ saveArray: [], movedFeatures: [] });
        }
    }, [editData]);

    useEffect(() => {
        urlLayers[selectedKey].layer.setVisible(checked);
    }, [checked, selectedKey, urlLayers]);

    const onChange = (checked: boolean, selectedKey: string, e?: React.SyntheticEvent<Element, Event>) => {
        e?.stopPropagation();
        setChecked((prev) => !prev);
    };

    const onOpenClick = () => {
        if (!open) {
            controlHandler.activeILayer = {
                ...urlLayers[selectedKey],
                extra: (controlHandler.activeILayer.extra ?? 0) + 1
            };
            const iLayer = urlLayers[selectedKey];
            iLayer.layer.setZIndex(++layers.urlLayerZIndex);
            if (!urlLayers[selectedKey].layer.getVisible()) {
                urlLayers[selectedKey].layer.setVisible(true);
                setChecked(true);
            }
            setCurr({ index: selectedKey, state: States.Open });
        } else {
            setCurr({ index: selectedKey, state: States.Closed });
        }
        setOpen((prev) => !prev);
    };

    const onToolClicked = (mode: Modes) => {
        setMode(() => mode);
        const layer = urlLayers[selectedKey].layer as CTLayer;
        switch (mode) {
            case Modes.Edit:
                setCurr({ index: selectedKey, state: States.Editing });
                // Hide textSVG's and make circles bigger to make editing easier
                if (layer.options.svgString && layer.options.shapeType === shapeTypes.Point) {
                    controlHandler.currentFloor = floor;
                    layer.setStyle((f, r) => {
                        const style = polyLayerStyle(f, r, layer, floor);
                        if (layer.options.shapeType === 'point')
                            style[0] = new Style({
                                image: undefined
                            });
                        style[1].setGeometry(
                            new Circle((f as Feature<Point>).getGeometry()?.getCoordinates() ?? [], 4 * r)
                        );
                        return style;
                    });
                }
                controlHandler.activeILayer = {
                    ...urlLayers[selectedKey],
                    extra: (controlHandler.activeILayer.extra ?? 0) + 1
                };
                // use the appropriate editing tool
                const shapeType: string | undefined = layer.options.shapeType;
                switch (shapeType) {
                    case shapeTypes.Point:
                        controlHandler.activeToolType = Tools.Point;
                        break;
                    case shapeTypes.Line:
                        controlHandler.activeToolType = Tools.LineString;
                        break;
                    case shapeTypes.Polygon:
                        controlHandler.activeToolType = Tools.Polygon;
                        break;
                }
                break;

            case Modes.Delete:
                setCurr({ index: selectedKey, state: States.Editing });
                // Hide textSVG's and make circles bigger to make editing easier
                if (layer.options.svgString) {
                    layer.setStyle((f, r) => {
                        const style = polyLayerStyle(f, r, layer, floor);
                        style[0] = new Style({
                            image: undefined
                        });
                        style[1].setGeometry(
                            new Circle((f as Feature<Point>).getGeometry()?.getCoordinates() ?? [], 4 * r)
                        );
                        return style;
                    });
                }
                controlHandler.activeToolType = Tools.Delete;
                break;

            case Modes.Save:
                setCurr({ index: selectedKey, state: States.Closed });
                // use select tool => we are done editing
                controlHandler.activeToolType = Tools.Select;
                setMode(Modes.Select);
                // setOpen(false);
                // SaveStatus.Labels triggers an event in EditingControl.ts which in turn
                // calls the setEditData below, to get the newest data to this file
                // This also triggers an effect above, that saves the data
                controlHandler.saveStatus = SaveStatus.Labels;
                // Reset feature styling
                layer.setStyle((f, r) => polyLayerStyle(f, r, layer, floor));
                break;

            case Modes.Cancel:
                setCurr({ index: selectedKey, state: States.Closed });
                setOpen(false);
                setMode(() => Modes.Select);
                setEditData({ saveArray: [], movedFeatures: [] });
                controlHandler.saveStatus = SaveStatus.Cancel;
                controlHandler.activeToolType = Tools.Select;
                // Reset feature styling
                // if (layer.options.svgString) {
                layer.setStyle((f, r) => polyLayerStyle(f, r, layer, floor));
                // }
                break;
        }
    };

    const svgText = (urlLayers[selectedKey].layer as CTLayer).options.svgString;

    // Enable editing panel if no layer is being edited and floor has no shifted features
    const editEnabled = showArrow && !(urlLayers[selectedKey].layer as CTLayer).options?.readOnly && floor !== 'A';

    return (
        <>
            <Stack
                ref={stackRef}
                key={`stack-${selectedKey}`}
                paddingBottom={'4px'}
                direction={'row'}
                justifyContent={'space-between'}
            >
                <FormControlLabel
                    onChange={(e, checked) => onChange(checked, selectedKey, e)}
                    control={
                        <Checkbox
                            checked={checked}
                            size='small'
                            sx={{ padding: 0, paddingLeft: '10px', paddingRight: '4px', paddingTop: '1.5px' }}
                        />
                    }
                    label={
                        <Grid container>
                            {svgText && (
                                <Grid item xs='auto'>
                                    <Stack alignItems={'center'} sx={{ mr: 1, ml: 1 }}>
                                        <Box sx={{ height: '16px', width: '16px' }}>
                                            {getLayerSvg({ layers, selectedKey })}
                                        </Box>
                                        <TextSVG
                                            text={svgText}
                                            hexColor={(urlLayers[selectedKey].layer as CTLayer).options.color}
                                        />
                                    </Stack>
                                </Grid>
                            )}
                            <Grid item xs='auto'>
                                <Typography sx={{ margin: 0, wordBreak: 'break-word' }}>{name}</Typography>
                            </Grid>
                        </Grid>
                    }
                />
                <IconButton
                    size='small'
                    sx={{ padding: 0, visibility: editEnabled ? 'visible' : 'hidden' }}
                    onClick={onOpenClick}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </Stack>
            {(editEnabled || open) && (
                <Collapse in={open} className='layer-options-collapse'>
                    <Stack direction={'row'} spacing={1} justifyContent='center' sx={{ pt: '8px', pb: '16px' }}>
                        <Button
                            sx={{ padding: '4px', minWidth: '26px' }}
                            variant={mode === Modes.Edit ? 'outlined' : 'contained'}
                            size='small'
                            key='edit'
                            color={'info'}
                            onClick={() => onToolClicked(Modes.Edit)}
                        >
                            <EditIcon />
                        </Button>
                        <Button
                            sx={{ padding: '4px', minWidth: '26px' }}
                            variant={mode === Modes.Delete ? 'outlined' : 'contained'}
                            size='small'
                            key='delete'
                            color={'error'}
                            onClick={() => onToolClicked(Modes.Delete)}
                        >
                            <DeleteIcon />
                        </Button>
                        <Button
                            sx={{ padding: '4px', minWidth: '26px' }}
                            variant={'contained'}
                            size='small'
                            key='save'
                            color={'success'}
                            onClick={() => onToolClicked(Modes.Save)}
                        >
                            <SaveIcon />
                        </Button>
                        <Button
                            sx={{ padding: '4px', minWidth: '26px' }}
                            variant={'contained'}
                            size='small'
                            key='cancel'
                            color={'warning'}
                            onClick={() => onToolClicked(Modes.Cancel)}
                        >
                            <CancelIcon />
                        </Button>
                    </Stack>
                    <Collapse in={true} sx={{ pb: '8px' }}>
                        {editData.saveArray
                            .sort((a, b) => a.label - b.label)
                            .map((f, i) => (
                                <TextField
                                    key={`text-${i}`}
                                    fullWidth
                                    autoComplete={'off'}
                                    label={f.label}
                                    variant='filled'
                                    onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                                        (f.label = e.target.value)
                                    }
                                />
                            ))}
                    </Collapse>
                </Collapse>
            )}
        </>
    );
});

// refs outside of the component, so they are usable in the exported
// function (used in EditingControl to send data from the map to here)
const refs: IDictionary<React.RefObject<IRef>, string> = {};
let currRef: string;
let eChangeFloor: ((floor: string) => void) | undefined;

export const setEditData = (saveArr: IDictionary[], movedFeatures: Feature[]) => {
    refs[currRef]?.current?.setEditData({ saveArray: saveArr, movedFeatures });
};

export const changeFloor = (floor?: string) => {
    if (floor) eChangeFloor?.(floor);
};

/** List of floors. K is Basement, T is roof and A is All, which means A displays a translated view of all floors */
export const floors = ['K', 'S', '1', '2', '3', '4', '5', '6', '7', 'T', 'A'] as const;

const CreateLayerGui = ({ layers, controlHandler }: ICreateGui) => {
    const urlLayers = layers.getUrlLayers();
    const [curr, setCurr] = useState({ index: '', state: States.Closed });
    // Use changeFloor instead of setFloor
    const [floor, setFloor] = useState('');
    const [layerCollapseOpen, setLayerCollapseOpen] = useState(true);
    const { shiftRooms } = useShiftRooms();

    const changeFloor = (_floor: string) => {

        setFloor(_floor);

        // Change feature visibility / style
        Object.keys(urlLayers).forEach((l) => {
            const layer = urlLayers[l].layer as CTLayer;
            if (layer.options.type === 'POLY')
                layer.setStyle((feature, resolution) => {
                    const style = polyLayerStyle(feature, resolution, layer, _floor);
                    return style;
                });
        });
    };

    useEffect(() => {
        if (floor === 'A') return shiftRooms();
    }, [floor, shiftRooms]);

    useEffect(() => {
        if (floor !== 'A') return;

        const currentBaseKey = controlHandler.activeBaseILayerKey;
        const labelForNone = document.querySelector(`div.ol-layerControl-content label#none`) as HTMLLabelElement;
        labelForNone.click();

        return () => {
            const labelForCurrent = document.querySelector(
                `div.ol-layerControl-content label#${currentBaseKey}`
            ) as HTMLLabelElement;
            console.log('Label: ', labelForCurrent);
            labelForCurrent.click();
        };
    }, [controlHandler, floor]);

    eChangeFloor = changeFloor;

    // curr is set everytime a layer is edited
    useEffect(() => {
        currRef = curr.index;
        // when a new one opens, make sure everything else is closed
        // the components are memoed, so this will only impact the open ones
        if (curr.state === States.Open) {
            Object.keys(refs).forEach((key) => {
                if (key !== curr.index) {
                    refs[key].current?.setOpen(false);
                }
            });
        }
        // when editing hide the arrow to expand for all elements, so that only
        // one layer can be edited at a time
        else if (curr.state === States.Editing) {
            Object.keys(refs).forEach((key) => {
                refs[key].current?.setShowArrow(false);
            });
        } else if (curr.state === States.Closed) {
            Object.keys(refs).forEach((key) => {
                refs[key].current?.setShowArrow(true);
            });
        }
    }, [curr]);

    return (
        <>
            <Stack direction={'row'}>
                <Typography variant='h6' sx={{ mt: '0px' }}>
                    Lokaler
                </Typography>
                <Switch
                    checked={floor !== ''}
                    onChange={() => changeFloor(floor === '' ? 'S' : '')}
                    disabled={curr.state === States.Editing}
                ></Switch>
            </Stack>
            <Stack
                direction={'row'}
                spacing={0.5}
                sx={{
                    border: 'solid 1px',
                    borderColor: 'grey',
                    borderRadius: '4px',
                    width: 'fit-content',
                    mt: '4px',
                    height: '30px',
                    p: '2px'
                }}
            >
                {floors.map((f) => {
                    return (
                        <Button
                            key={f}
                            variant={floor === f ? 'contained' : 'text'}
                            disabled={curr.state === States.Editing}
                            size='small'
                            sx={{ minWidth: '10px' }}
                            onClick={() => changeFloor(f)}
                        >
                            {f}
                        </Button>
                    );
                })}
            </Stack>
            <Stack key={`stack-baggrundskort`} direction={'row'} justifyContent={'space-between'} onClick={() => setLayerCollapseOpen(o => !o)}>
                <Typography variant='h6'>Valgte lag</Typography>
                <IconButton size='small' sx={{ padding: 0 }}>
                    {layerCollapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </Stack>
            <Collapse in={layerCollapseOpen} className='layer-options-collapse'>
                {Object.keys(urlLayers).map((l, index) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const ref = useRef<IRef>(null);
                    refs[l] = ref;
                    return (
                        <TProvider defaultValue={Modes.Select} key={l}>
                            <div style={{ background: index % 2 ? '#f5f5f5' : 'white' }}>
                                <CreateLayerEditMenu
                                    ref={ref}
                                    setCurr={setCurr}
                                    layers={layers}
                                    controlHandler={controlHandler}
                                    selectedKey={l}
                                    floor={floor}
                                />
                            </div>
                        </TProvider>
                    );
                })}
            </Collapse>
        </>
    );
};

export default CreateLayerGui;
