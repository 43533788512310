import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { useAppDispatch, useAppSelector } from '../redux-base-hooks';
import { useArgsCallback, useArgsEffect } from '../use-args';
import { useEffect, useState } from 'react';

import Api from '../../networking/api';
import { IDictionary } from '../../utils/types';
import useMountEffect from '../use-mount-effect';

//#region Redux slice

interface IUpdateItem<T = unknown> {
    key: string;
    value: T;
    time: number;
}

type IState = IDictionary<{ value: unknown; updated: number /* Date */ }>;

const initialState: IState = {};

const fetchSlice = createSlice({
    name: 'fetch',
    initialState: initialState,
    reducers: {
        updateValue: {
            reducer(state: IState, action: PayloadAction<IUpdateItem>) {
                state[action.payload.key] = { value: action.payload.value, updated: action.payload.time };
            },
            prepare(key: string, value: unknown) {
                return { payload: { key, value, time: Date.now() } };
            }
        }
    }
});

export const fetchReducer = fetchSlice.reducer;

//#endregion Reduc slice
//#region Fetch

//#region Interfaces

/**
 * IFetchOptions:
 * @param config AxiosRequestConfig
 * @param api boolean
 * @param redux IReduxOptions
 */
export interface IFetchOptions<TResponseData = unknown> {
    /**
     * Fetch configuration similar to Fetch's requestInit
     * @default: {}
     */
    config?: AxiosRequestConfig;

    /**
     * Flag indicates wether the fetch is to the api defined in config.json or not
     * @default: true
     */
    api?: boolean;

    /**
     * Options for saving data in redux
     * @default {
     *     save: false,
     *     key: ''
     * }
     */
    redux?: IReduxOptions;

    /**
     * Flag indicate wether the fetch should trigger automatically on first render, and when key / url changes
     * @default true
     */
    autoFetch?: boolean;

    /**
     * Function triggered on a successful fetch
     * @param response the axios response object
     */
    onSuccessfulFetch?(response: AxiosResponse<TResponseData, unknown>): void;

    /**
     * Function triggered on a fetch error
     * @param error The error object
     */
    onError?(error: Error): void;
}

/**
 * Options for saving in redux
 * - save: Flag indicating wether data should be saved
 * - key: Dictionary key to save / retrieve data
 * - refetchTimer: Milliseconds until data should be retrieved again. Doesn't work if autoFetch is set false in fetchOptions
 */
export interface IReduxOptions {
    save: boolean;
    key: string;
    refetchTimer?: number;
}
//#endregion Interfaces

/**
 * Fetches data and returns an array of relevant states and functions
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param url The url to fetch from
 * @param options - Options for how to fetch or save data
 * @returns array of values in the following order
 * - data - The data fetched
 * - isLoading - Bool indicating data is being fetched
 * - isError - Bool indicating there is an error
 * - error - The error object
 * - refetch - Function to manually trigger a refetch
 * - hasFetched - Bool indicating a fetch has happened at least once
 *
 * WARNING: changes to options.config will not be noticed, and thus won't trigger a refetch
 */
function useFetch<T = unknown>(
    url: string,
    {
        config = {},
        api = true,
        redux = {
            save: false,
            key: ''
        },
        autoFetch = true,
        onSuccessfulFetch,
        onError
    }: IFetchOptions<T> = {}
) {
    const fullUrl = Api.createUrl(url);

    const reduxState = useAppSelector((storeState) => storeState.fetch[redux.key]);
    // Store dispatcher
    const dispatch = useAppDispatch();

    // Statuser på fetch
    const [data, setData] = useState<T>(() => (redux.save ? reduxState?.value : undefined) as T);
    const [isLoading, setIsLoading] = useState(autoFetch);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState<Error>();
    const [status, setStatus] = useState<number>();
    const [hasFetched, setHasFetched] = useState(false);

    const refetch = useArgsCallback(
        async ([api, config, dispatch, fullUrl, rKey, rSave], [onError, onSuccessfulFetch, url]) => {
            setIsLoading(true);

            // Sæt mulige api headers
            const headers: AxiosRequestHeaders = config.headers ?? {};
            if (api) headers['Authorization'] = `Bearer ${Api.token}`;
            config.headers = headers;

            // Fetch
            try {
                const response = await axios.get<T>(fullUrl, config);
                const responseData = await response.data;

                if (rSave) {
                    dispatch(fetchSlice.actions.updateValue(rKey, responseData));
                } else {
                    setData(responseData);
                }

                setStatus(response.status);
                setIsLoading(false);
                setError(undefined);
                setIsError(false);
                setHasFetched(true);

                onSuccessfulFetch?.(response);
            } catch (error) {
                setIsLoading(false);
                setIsError(true);
                setError(error as Error);
                setHasFetched(true);
                onError?.(error as Error);
            }
        },
        [api, config, dispatch, fullUrl, redux.key, redux.save] as const,
        [onError, onSuccessfulFetch, url] as const
    );

    // Refetch data hvis timer er udløbet
    useEffect(() => {
        if (
            autoFetch &&
            redux.refetchTimer !== undefined &&
            reduxState?.updated !== undefined &&
            reduxState.updated + redux.refetchTimer < Date.now() &&
            !isLoading
        ) {
            refetch();
        }
    });

    useArgsEffect(
        (_, [autoFetch, rsValue, isLoading, url, refetch]) => {
            if (autoFetch && rsValue === undefined && !isLoading) {
                if (url.includes('Kontakt')) console.log('key/query change trigger kontaktliste');
                refetch();
            }
        },
        [redux.key, fullUrl] as const,
        [autoFetch, reduxState?.value, isLoading, url, refetch] as const
    );

    // Ændre data hvis data i store ændrer sig
    useArgsEffect(
        ([reduxState], [autoFetch, save]) => {
            if (autoFetch && save && reduxState?.value !== undefined) setData(reduxState.value as T);
        },
        [reduxState] as const,
        [autoFetch, redux.save] as const
    );

    useMountEffect(() => {
        if (!autoFetch) return;

        if (reduxState?.value === undefined) {
            if (url.includes('Kontakt')) console.log('first trigger kontaktliste');
            refetch();
        } else {
            setIsLoading(false);
        }
    });

    return [data, isLoading, isError, error, status, refetch, hasFetched] as const;
}

//#endregion Fetch

export default useFetch;
