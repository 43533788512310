import { cloneElement, useCallback, useMemo } from 'react';

import { IDictionary } from '../../shared/utils/types';
import { RestrictedProps } from './interfaces';
import { UserPermissions } from './interfaces';
import { useAppSelector } from '../../shared/hooks/redux-base-hooks';

/**
 * Permission hook
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @returns
 * permissions: An array of permissions
 * hasPermissions: A function to check if a user has the required permissions
 */
export const usePermissions = () => {
    const permissions = useAppSelector((s) => s.permissions.permissions);
    const permMap: IDictionary<boolean> = useMemo(() => {
        const map: IDictionary<boolean> = {};
        if (permissions) for (let i = 0; i < permissions.length; i++) map[permissions[i]] = true;
        return map;
    }, [permissions]);

    const hasPermissions = useCallback(
        (...required: UserPermissions[]) => {
            if (required.includes(UserPermissions.Denied)) return false;
            if (permissions?.includes(UserPermissions.Admin)) return true;
            for (let i = 0; i < required.length; i++) if (!permMap[required[i]]) return false;
            return true;
        },
        [permMap, permissions]
    );

    return {
        permissions: permissions ?? [],
        hasPermissions,
        awaitingPermissions: permissions == null
    };
};

/**
 * A component which conditionally renders children based on user permissions.
 * Alternatively, it can modify childrens props based on user permissions
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
const Restricted = ({ permissions, children, renderUnauthorized, unauthorizedProps }: RestrictedProps) => {
    const { hasPermissions, awaitingPermissions } = usePermissions();
    const _permissions = typeof permissions === 'string' ? [permissions] : permissions;

    if (hasPermissions(..._permissions)) return <>{children}</>;

    if (unauthorizedProps !== undefined)
        return <>{cloneElement(children, { ...unauthorizedProps, awaitingPermissions })}</>;

    if (renderUnauthorized != null && typeof renderUnauthorized !== 'string')
        return <>{cloneElement(renderUnauthorized, { awaitingPermissions })}</>;

    return <>{renderUnauthorized}</>;
};

export default Restricted;
