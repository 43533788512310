import React, { ReactNode } from 'react';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Divider } from '@mui/material';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import List from '@mui/material/List/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemButton from '@mui/material/ListItemButton/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import MapIcon from '@mui/icons-material/Map';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Restricted from '../restricted/restricted';
import { UserPermissions } from '../restricted/interfaces';
import useHistoryNavigate from '../../shared/hooks/extended-hooks/use-history-navigate';

interface IBurgerMenuListProps {
    close: VoidFunction;
}

interface IBurgerMenuListItemProps {
    path?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    tekst: string;
    close?: VoidFunction;
    icon?: ReactNode;
    disabled?: boolean;
}

/**
 *
 * @param path hvor skal menu-item'et lede hen
 * @param tekst tekst som vises ved siden af ikonet
 * @param icon mui-icon
 * @param close funktion til at skjule menuen, når der klikkes på et element
 * @param disabled til når restrictions skal kunne disable et element
 *
 * @returns
 */
export const BurgerMenuListItem = ({
    path,
    onClick: _handleClick,
    tekst,
    close,
    icon,
    disabled = false
}: IBurgerMenuListItemProps) => {
    const { navigate } = useHistoryNavigate();

    const handleClick: React.MouseEventHandler<HTMLDivElement> =
        _handleClick ??
        (() => {
            path && navigate(path);
            close?.();
        });

    return (
        <ListItem key={tekst} disablePadding>
            <ListItemButton onClick={handleClick} disabled={disabled}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={tekst} />
            </ListItemButton>
        </ListItem>
    );
};

/**
 *
 * @param close funktion til at skjule menuen når der klikkes på et element
 * @returns Liste af elementer til draweren
 */
export const BurgerMenuList = ({ close }: IBurgerMenuListProps) => {
    return (
        <List>
            <Restricted permissions={UserPermissions.Drift}>
                <BurgerMenuListItem tekst='Drift' path='/Drift?teamonly=false' close={close} icon={<ListAltIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Team}>
                {/* <Restricted permissions={UserPermissions.Drift_Team}> */}
                <BurgerMenuListItem tekst='Team' path='/Drift?teamonly=true' close={close} icon={<GroupsIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Drift || UserPermissions.Team}>
                <Divider />
            </Restricted>
            <Restricted permissions={UserPermissions.Rapport}>
                <BurgerMenuListItem tekst='Rapport' path='/Rapport' close={close} icon={<PostAddIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Map} unauthorizedProps={{ disabled: true }}>
                <BurgerMenuListItem tekst='Kort' path='/Map' close={close} icon={<MapIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Admin}>
                <BurgerMenuListItem tekst='Admin' path='/Admin' close={close} icon={<AdminPanelSettingsIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Bygningsdelskort}>
                <BurgerMenuListItem
                    tekst='Bygningsdelskort'
                    path='/Bygningsdelskort'
                    close={close}
                    icon={<MapsHomeWorkIcon />}
                />
            </Restricted>
            <Restricted permissions={UserPermissions.Syn}>
                <BurgerMenuListItem tekst='Syn' path='/Syn' close={close} icon={<AssignmentIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Service}>
                <BurgerMenuListItem tekst='Service' path='/Service' close={close} icon={<HomeRepairServiceIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Admin}>
                <BurgerMenuListItem tekst='Denied' path='/Denied' close={close} icon={<DoNotDisturbOnIcon />} />
            </Restricted>
        </List>
    );
};

export default BurgerMenuList;
