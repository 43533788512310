import { useLayoutEffect, useRef } from 'react';

import { FeatureProperty } from '../../feature-utility';
import Grid from '@mui/material/Grid/Grid';
import { IOverlayProps } from '../../react-components/clickable-features-overlays';
import Typography from '@mui/material/Typography/Typography';
import { WideButton } from '../../../../mui/styled-mui';

const ServiceOverlay = (props: IOverlayProps) => {
    const mProps = useRef(props);
    const featureData: { id: number, value: string } = props.feature.get(FeatureProperty.Data);

    useLayoutEffect(() => {
        const offset = mProps.current.overlay.getOffset();
        mProps.current.overlay.setOffset([offset[0], -40]);
    }, []);

    const handleDetailsClicked = () => {
        window.location.href = `?id=${featureData.id}`;
    };

    const closeFeature = () => {
        props.close();
    };

    return (
        <>
            <Typography variant='h6'>{featureData.value}</Typography>
            {/* <CenterRightBox display='inline-flex' height={'100px'} width={'50%'}>
                    <GraphMini url={`/Drift/Status?enhedsys=${featureData.id}`} />
                </CenterRightBox>
                <CenterLeftBox display='inline-flex' height={'100px'} width={'50%'}>
                    <GraphMini url={`/Drift/Status?enhedsys=${featureData.id}`} />
                </CenterLeftBox> */}
            <Grid container spacing={1} width='100%'>
                <Grid item xs={6} padding={'4px'}>
                    <WideButton variant='contained' onClick={handleDetailsClicked}>
                        Detaljer
                    </WideButton>
                </Grid>
                <Grid item xs={6} padding={'4px'}>
                    <WideButton variant='outlined' onClick={closeFeature}>
                        Luk
                    </WideButton>
                </Grid>
            </Grid>
        </>
    );
};

export default ServiceOverlay;
