import route, { useCliRoute } from './route';

export const useCliNavigation = () => {
    useCliRoute();
};

export const navigation = {
    ...route
};

export default navigation;
