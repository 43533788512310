import { ClickableFeature, ClickableFeatureType } from '../ClickableFeatures';
import { Fill, Stroke, Style, Text } from 'ol/style';

import { Options as BOptions } from 'ol/layer/BaseVector';
import { BaseIconId } from '../SVG';
import CTLayer from '../CTLayer';
import { Extent } from 'ol/extent';
import Feature from 'ol/Feature';
import { FeatureProperty } from '../feature-utility';
import Geometry from 'ol/geom/Geometry';
import { IMapLayer } from '../../caretaker-map';
import { OrderFunction } from 'ol/render';
import PluggableMap from 'ol/PluggableMap';
import { StyleLike } from 'ol/style/Style';
import VectorSource from 'ol/source/Vector';
import { WKT } from 'ol/format';
import { decodeJsonResponse } from './DataIO';

export interface Options {
    className?: string;
    opacity?: number;
    visible?: boolean;
    extent?: Extent;
    zIndex?: number;
    minResolution?: number;
    maxResolution?: number;
    minZoom?: number;
    maxZoom?: number;
    renderOrder?: OrderFunction;
    renderBuffer?: number;
    source?: VectorSource<Geometry>;
    map?: PluggableMap;
    declutter?: boolean;
    style?: StyleLike;
    updateWhileAnimating?: boolean;
    updateWhileInteracting?: boolean;
}

/**
 * @deprecated Use GeometryLayer instead
 */
export class BygLayer extends CTLayer {
    private static wktFormat = new WKT();

    public baseIconID: BaseIconId;
    public color!: string;

    public addPointsFromWKTPoints(features: Feature<Geometry>[]) {
        const notNullFeatures = features.filter((f) => f != null);
        this.getSource()?.addFeatures(notNullFeatures);
    }

    public async loadBygPolygons(dataEndpointUri: string, geometryColumnName: string) {
        let json;
        try {
            // eslint-disable-next-line no-restricted-syntax
            json = await fetch(dataEndpointUri)
                .then((r) => r.json())
                .then((json) => decodeJsonResponse(json));
        } catch (err) {
            json = [];
        }
        this.addPointsFromWKTPoints(
            json.map((o) => {
                const feature =
                    o[geometryColumnName] === '' || o[geometryColumnName] === undefined
                        ? new Feature<any>()
                        : BygLayer.wktFormat.readFeature(o[geometryColumnName]);
                if (feature != null) {
                    feature.set(FeatureProperty.Data, o);
                    (feature as ClickableFeature).dataColumns = ['BygTerSYS', 'ENHEDSYS', 'STATUS', 'NAVN'];
                    (feature as ClickableFeature).webPage =
                        '/Byg/BygMenu/{BygTerSYS}?enhedId={ENHEDSYS}&caretakerMap=true';
                    (feature as ClickableFeature).featureType = ClickableFeatureType.Byg;

                    // Style som funktion så værdier kan ændres dynamisk
                    feature.setStyle(() => {
                        return new Style({
                            fill: new Fill({
                                color: 'rgb(76, 94, 99, 1)'
                            }),
                            text: new Text({
                                font: '12px "Segoe UI", Verdana, Tahoma, sans-serif',
                                fill: new Fill({ color: '#FFF' }),
                                stroke: new Stroke({
                                    color: '#000',
                                    width: 2
                                }),
                                text: o['LABEL'] ?? ''
                            })
                        });
                    });
                }
                return feature;
            })
        );
    }

    public clearFeatures() {
        this.getSource()?.clear();
    }

    // Options here is a mess after being changed too many times
    constructor(options: IMapLayer) {
        const _options = (options ?? {}) as BOptions<VectorSource<any>>;
        _options.source = new VectorSource();
        //options.minZoom ??= 13;
        _options.minZoom = _options.minZoom ?? 12;
        //options.maxZoom ??= 100;
        _options.maxZoom = _options.maxZoom ?? 100;
        _options.zIndex = 1000;
        super(options, _options);
        this.baseIconID = BaseIconId.Bygning;
        this.loadBygPolygons = this.loadBygPolygons.bind(this);
        this.addPointsFromWKTPoints = this.addPointsFromWKTPoints.bind(this);
    }
}
