import useBreakpoints from './use-breakpoints';
import { useMobileOrientation } from 'react-device-detect';

/**
 * Hook to get css for the maximum displayable height of content on the page
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @returns a css string equal to the height of the screen minus the nav-bar
 */
const usePageHeight = () => {
    const { isLandscape } = useMobileOrientation();
    const { sm } = useBreakpoints();
    const offset = sm ? 64 : isLandscape ? 48 : 56;
    return `calc(100vh - ${offset}px)`;
};

export default usePageHeight;
