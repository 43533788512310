import FormControl from '@mui/material/FormControl';
import styled from '@mui/styles/styled';

export const AUFormControl = styled(FormControl)((props) => ({
    '& .MuiFormHelperText-root': {
        color: props.error ? 'red' : undefined
    },
    '& label': {
        color: props.error ? 'red' : undefined
    }
}));
