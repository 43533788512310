import { BXORA, IDictionary } from '../../shared/utils/types';

/**
 * Props for the restricted component
 *
 * Note that unauthorizedProps and renderUnauthorized are mutually exclusive
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
export type RestrictedProps = BXORA<
    {
        /**
         * Permissions needed to access the child component. Can either be a single permission or an array of permissions
         */
        permissions: UserPermissions | UserPermissions[];
        children: JSX.Element;
    },
    [
        {
            /**
             * Props to pass to the child element if not authorized
             */
            unauthorizedProps?: IDictionary;
        },
        {
            /**
             * Component to show if not authorized
             */
            renderUnauthorized?: JSX.Element | string;
        }
    ]
>;

/**
 * Props for the RestrictedRoute component
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
export interface RestrictedRouteProps {
    /**
     * Permissions needed to access the child component. Can either be a single permission or an array of permissions
     */
    permissions: UserPermissions | UserPermissions[];

    /**
     * The path of the route
     */
    path?: string;

    /**
     * Is this the index route?
     */
    index?: boolean;

    /**
     * Element to show when the path is matched
     */
    element: JSX.Element;
}

export enum UserPermissions {
    Admin = 'Admin',
    Bygningsdelskort = 'Bygningsdelskort',
    Bygningsdelskort_Lev = 'Bygningsdelskort.Lev',
    Bygningsdelskort_Bygherre = 'Bygningsdelskort.Bygherre',
    Drift = 'Drift',
    Drift_Create = 'Drift.Create',
    Drift_Read = 'Drift.Read',
    Drift_Update = 'Drift.Update',
    Drift_Delete = 'Drift.Delete',
    Drift_Journal = 'Drift.Journal',
    Drift_Kontakter = 'Drift.Kontakter',
    Drift_Link = 'Drift.Link',
    Drift_Lister = 'Drift.Lister',
    Drift_Rul = 'Drift.Rul',
    Drift_Team = 'Drift.Team',
    Drift_Tegninger = 'Drift.Tegninger',
    Drift_QR = 'Drift.QR',
    Map = 'Map',
    MeldFejl = 'MeldFejl',
    Rapport = 'Rapport',
    Service = 'Service',
    Settings = 'Settings',
    Syn = 'Syn',
    Team = 'Drift.Team',

    Denied = 'Denied'
}
