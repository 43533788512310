import { IInput, ItemTypes } from './interfaces';

import { IDictionary } from '../../../shared/utils/types';
import __ from '../../../shared/utils/lodash-expansions';

const defaultIInput = {
    label: '',
    labelMode: 'floating',
    required: false,
    readOnly: false,
    autoComplete: 'off'
};

/** Interlace with default input */
const d = (input: Partial<IInput>) => {
    return __.deepMerge(defaultIInput, input);
};

export const defaultIInputs: IDictionary<Partial<IInput>, ItemTypes> = {
    [ItemTypes.NoInput]: d({}),
    [ItemTypes.String]: d({
        editorType: 'dxTextBox',
        maxLength: 50
    }),
    [ItemTypes.Block]: d({
        editorType: 'dxTextArea',
        editorOptions: {
            minHeight: `${3 * 23}px`,
            autoResizeEnabled: true
        }
    }),
    [ItemTypes.Number]: d({
        editorType: 'dxNumberBox',
        editorOptions: {
            format: '#.',
            step: 0
        }
    }),
    [ItemTypes.Decimal]: d({
        editorType: 'dxNumberBox',
        editorOptions: {
            format: '#.##',
            step: 0
        }
    }),
    [ItemTypes.Date]: d({
        editorType: 'dxDateBox'
    }),
    [ItemTypes.Choice]: d({
        editorType: 'dxRadioGroup',
        editorOptions: {
            displayExpr: 'value',
            valueExpr: 'id',
            layout: 'horizontal'
        }
    }),
    [ItemTypes.File]: d({}),
    [ItemTypes.Combo_ConditionalBlock]: d({}),
    [ItemTypes.Combined]: d({}),
    [ItemTypes.Lookup]: d({
        editorType: 'dxLookup'
    }),
    [ItemTypes.Default]: defaultIInput
};
