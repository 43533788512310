import Button from '@mui/material/Button/Button';
import { DecisionProgressButtonsProps } from './interfaces';
import { HorizontalCenterBox } from '../mui/styled-mui';
import { IDictionary } from '../../shared/utils/types';

export type { DecisionProgressButtonsProps } from './interfaces';

const DecisionProgressButtons = (props: DecisionProgressButtonsProps) => {
    const { handleNext, handleBack, handleFinished, handleCancel, buttonProps } = props;

    // Returns a prop to the given button. All parameters are typed dynamically from the type of buttonProps
    type P = NonNullable<typeof buttonProps>;
    const getProp = <T,>(target: keyof P, propKey: keyof NonNullable<P[keyof P]>, fallback?: T): T => {
        return (buttonProps as IDictionary)?.[target]?.[propKey] ?? fallback;
    };

    return (
        <HorizontalCenterBox sx={{ mb: 2 }}>
            {handleNext && (
                <Button
                    variant={getProp('next', 'variant', 'contained')}
                    onClick={handleNext}
                    disabled={getProp('next', 'disabled', false)}
                    sx={getProp('next', 'sx', { mt: 1, mr: 1 })}
                    {...buttonProps?.next}
                >
                    {getProp('next', 'text', 'Næste')}
                </Button>
            )}
            {handleFinished && (
                <Button
                    variant={getProp('finished', 'variant', 'contained')}
                    onClick={handleFinished}
                    disabled={getProp('finished', 'disabled', false)}
                    color='success'
                    sx={getProp('finished', 'sx', { mt: 1, mr: 1 })}
                    {...buttonProps?.finished}
                >
                    {getProp('finished', 'text', 'Færdig')}
                </Button>
            )}
            {handleBack && (
                <Button
                    variant={getProp('back', 'variant', 'outlined')}
                    onClick={handleBack}
                    disabled={getProp('back', 'disabled', false)}
                    sx={getProp('back', 'sx', { mt: 1, mr: 1 })}
                    {...buttonProps?.back}
                >
                    {getProp('back', 'text', 'Tilbage')}
                </Button>
            )}
            {handleCancel && (
                <Button
                    variant={getProp('cancel', 'variant', 'outlined')}
                    onClick={handleCancel}
                    disabled={getProp('cancel', 'disabled', false)}
                    sx={getProp('cancel', 'sx', { mt: 1, mr: 1 })}
                    {...buttonProps?.cancel}
                >
                    {getProp('cancel', 'text', 'Annuller')}
                </Button>
            )}
        </HorizontalCenterBox>
    );
};

export default DecisionProgressButtons;
