import navigation, { useCliNavigation } from './navigation';

import map from './map';

export const cli = {
    map,
    navigation
};

(window as any)['cli'] = cli;

export const useCli = () => {
    useCliNavigation();
};
