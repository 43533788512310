import ReactDOMServer from 'react-dom/server';
import UtilsString from '../../shared/utils/utils-string';
import { custom as customDialog } from 'devextreme/ui/dialog';

export interface IFile {
    id: number;
    name: string;
    url: string;
    alt?: string;
    type: 'image' | 'file' | 'other';
}

export const getFileName = (file: IFile, fallback: string): string => {
    if (!UtilsString.IsNullOrWhitespace(file.name)) return file.name;
    if (!UtilsString.IsNullOrWhitespace(file.alt)) return file.alt!;
    return fallback;
};

export const confirmDelete = async (type: 'image' | 'file'): Promise<boolean> => {
    const words = {
        image: 'billedet',
        file: 'filen'
    };

    return await customDialog({
        title: 'Slet',
        messageHtml: ReactDOMServer.renderToStaticMarkup(<p>Er du sikker på {words[type]} skal slettes</p>),
        buttons: [
            {
                text: 'Slet',
                type: 'danger',
                onClick: () => {
                    return true;
                }
            },
            {
                text: 'Anullér',
                onClick: () => {
                    return false;
                }
            }
        ]
    }).show();
};
