import AutoForm, { ItemTypes } from '../../../../auto-form';

import Api from '../../../../../shared/networking/api';
import { IDictionary } from '../../../../../shared/utils/types';
import useCentralizedSnackbar from '../../../../../shared/hooks/redux-use-centralized-snackbar';

interface IProps {
    closePopup(): void;
    onSubmitted(): void;
    onCanceled(): void;
    data: IDictionary;
}

const HelpdeskEditRender = ({ data, closePopup, onSubmitted, onCanceled }: IProps) => {
    const { enqueueSnackbar } = useCentralizedSnackbar();

    const handleSubmit = async (formData: FormData) => {
        formData.append('id', data.id);
        const promise = Api.put(`/Helpdesk`, formData);

        closePopup();

        const response = await promise;

        if (Api.ok(response)) {
            enqueueSnackbar(`Fejlmelding opdateret!`, { variant: 'success' });
            onSubmitted();
            return;
        }

        enqueueSnackbar('Noget gik galt, prøv igen senere', { variant: 'error' });
        onCanceled();
    };

    const handleCancel = () => {
        closePopup();
        onCanceled();
    };
    return (
        <AutoForm
            actionsPosition='header'
            editing={false}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            data={data}
            items={[
                {
                    colCountByScreen: {
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 2
                    },
                    items: [
                        {
                            label: 'Problem',
                            items: [
                                {
                                    id: 'problem',
                                    label: 'Problem',
                                    type: ItemTypes.String,
                                    required: true
                                },
                                {
                                    id: 'note',
                                    label: 'Note',
                                    type: ItemTypes.Block
                                }
                            ]
                        },
                        {
                            label: 'Bilag',
                            items: [
                                {
                                    id: 'files',
                                    type: ItemTypes.File
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'Information',
                    hideOnEdit: true,
                    colCountByScreen: {
                        xs: 2,
                        sm: 2,
                        md: 4,
                        lg: 4
                    },
                    items: [
                        {
                            id: 'enhed',
                            type: ItemTypes.Block,
                            editorOptions: {
                                minHeight: '23px'
                            },
                            readOnly: true,
                            colSpan: 2
                        },
                        {
                            id: 'byg',
                            label: 'Bygning',
                            type: ItemTypes.String,
                            readOnly: true,
                            colSpan: 2
                        },
                        {
                            id: 'status',
                            type: ItemTypes.String,
                            readOnly: true,
                            colSpan: 1
                        },
                        {
                            id: 'registreret',
                            type: ItemTypes.Date,
                            readOnly: true,
                            colSpan: 1
                        },
                        // {
                        //     id: "planlagtdato",
                        //     label: "Planlagt dato",
                        //     type: ItemTypes.Date,
                        //     readOnly: true,
                        //     colSpan: 1
                        // },
                        // {
                        //     id: "faerdiginden",
                        //     label: "Færdig inden",
                        //     type: ItemTypes.Date,
                        //     readOnly: true,
                        //     colSpan: 1
                        // },
                        {
                            id: 'aktiontaget',
                            label: 'Aktion taget',
                            type: ItemTypes.String,
                            readOnly: true,
                            colSpan: 2
                        },
                        {
                            id: 'lognote',
                            label: 'Log note',
                            type: ItemTypes.String,
                            readOnly: true,
                            colSpan: 2
                        },
                        {
                            id: 'meldtafnavn',
                            label: 'Meldt af',
                            type: ItemTypes.String,
                            readOnly: true,
                            colSpan: 2
                        },
                        // {
                        //     id: "meldtaftlf",
                        //     label: "Tlf",
                        //     type: ItemTypes.String,
                        //     readOnly: true,
                        //     colSpan: 1
                        // },
                        // {
                        //     id: "meldtafemail",
                        //     label: "Email",
                        //     type: ItemTypes.String,
                        //     readOnly: true,
                        //     colSpan: 1
                        // },
                        {
                            id: 'art',
                            type: ItemTypes.String,
                            readOnly: true,
                            colSpan: 1
                        },
                        {
                            id: 'prioritet',
                            type: ItemTypes.String,
                            readOnly: true,
                            colSpan: 1
                        },
                        {
                            id: 'opretdato',
                            label: 'Oprettet',
                            type: ItemTypes.Date,
                            readOnly: true,
                            colSpan: 1
                        },
                        {
                            id: 'opretbruger',
                            label: 'Oprettet af',
                            type: ItemTypes.String,
                            readOnly: true,
                            colSpan: 1
                        },
                        {
                            id: 'retdato',
                            label: 'Sidst rettet',
                            type: ItemTypes.Date,
                            readOnly: true,
                            colSpan: 1
                        },
                        {
                            id: 'retbruger',
                            label: 'Sidst rettet af',
                            type: ItemTypes.String,
                            readOnly: true,
                            colSpan: 1
                        }
                    ]
                }
            ]}
        />
    );
};

const GetHelpdeskEditRender = (props: IProps) => <HelpdeskEditRender {...props} />;

export default GetHelpdeskEditRender;
