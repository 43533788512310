import Button from '@mui/material/Button/Button';
import { DecisionStepContentWrapperProps } from './interfaces';
import LoadingScreen from '../../screens/loading';
import Typography from '@mui/material/Typography/Typography';
import { useEffect } from 'react';

export type { DecisionStepContentWrapperProps } from './interfaces';

const DecisionStepContentWrapper = ({
    isLoading,
    isError,
    hasFetched,
    refetch,
    setRefetch,
    children
}: DecisionStepContentWrapperProps) => {
    useEffect(() => {
        setRefetch(refetch);
    }, [refetch, setRefetch]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (isError) {
        return (
            <>
                <Typography variant='h5'>Netværksfejl</Typography>
                <Typography>Tryk på knappen herunder for at prøve igen</Typography>
                <Button variant='outlined' onClick={refetch}>
                    Prøv Igen
                </Button>
            </>
        );
    }

    if (hasFetched) {
        return <>{children}</>;
    }

    return (
        <>
            <Typography variant='h6'>Noget gik galt</Typography>
            <Typography>Tryk på knappen herunder for at komme videre</Typography>
            <Button variant='contained' onClick={refetch} sx={{ mt: 1, mb: 1.5 }}>
                Hent næste skridt
            </Button>
        </>
    );
};

export default DecisionStepContentWrapper;
