import DecisionTree, { DecisionTreeProps } from '../../../../decision-tree/decision-tree';
import EnhedLookup, { ISearchEnhed } from '../../../../data-getters/enhed-lookup';
import { useRef, useState } from 'react';

import Api from '../../../../../shared/networking/api';
import Container from '@mui/material/Container/Container';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import UtilsString from '../../../../../shared/utils/utils-string';
import { WideButton } from '../../../../mui/styled-mui';
import config from '../../../../../config';
import { isMobile } from 'react-device-detect';
import useCentralizedSnackbar from '../../../../../shared/hooks/redux-use-centralized-snackbar';

type TStepAmount = 6;

interface DecisionRenderProps {
    closePopup(): void;
    onSubmitted(): void;
    onCanceled(): void;
    formData?: DecisionTreeProps<TStepAmount>['formData'];
}

const labels: DecisionTreeProps<TStepAmount>['labels'] = [
    {},
    {},
    {},
    {},
    {},
    {
        label: 'Afslutning',
        description: 'Evt. kommentarer'
    }
];

interface EnhLookupProps {
    setEnhFieldId(id: string): void;
    cancel: VoidFunction;
}

export const EnhLookup = ({ setEnhFieldId, cancel: handleCancel }: EnhLookupProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [selection, setSelection] = useState<ISearchEnhed>();
    const handleLookupSelectionChanged = (selection: ISearchEnhed) => setSelection(selection);

    const handleConfirm = () => setEnhFieldId(selection?.id.toString() ?? '');

    return (
        <Container ref={containerRef} sx={{ paddingTop: '16px' }}>
            <Typography>Vælg en ejendom</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EnhedLookup onSelectionChanged={handleLookupSelectionChanged} onError={handleCancel} />
                </Grid>
                <Grid item xs={6}>
                    <WideButton onClick={handleConfirm} color='success' disabled={selection === undefined}>
                        Bekræft
                    </WideButton>
                </Grid>
                <Grid item xs={6}>
                    <WideButton onClick={handleCancel}>Annuller</WideButton>
                </Grid>
            </Grid>
        </Container>
    );
};

const DecisionRender = (props: DecisionRenderProps) => {
    const enhId = new URLSearchParams(window.location.search).get('enhId');
    const [enhFieldId, setEnhFieldId] = useState(enhId ?? '');
    const { closePopup, onSubmitted, onCanceled, formData } = props;
    const { enqueueSnackbar } = useCentralizedSnackbar();

    const _formData = [
        ...(formData ?? []),
        {
            name: 'enhSYS',
            value: enhFieldId
        }
    ];

    const handleSubmit = async (data: FormData) => {
        // Luk popup
        closePopup();

        // POST data
        const response = await Api.post('/Drift/Fejl?treeid=1', data);

        if (Api.ok(response)) {
            enqueueSnackbar(`Fejlmelding sendt!`, { variant: 'success' });
            onSubmitted();
            return;
        }

        enqueueSnackbar('Noget gik galt, prøv igen senere', { variant: 'error' });
        onCanceled();
    };

    const handleCancel = () => {
        onCanceled();
        closePopup();
    };

    return UtilsString.IsNullOrWhitespace(enhFieldId) ? (
        <EnhLookup setEnhFieldId={setEnhFieldId} cancel={handleCancel} />
    ) : (
        <DecisionTree
            url={`${config.NODE_TEST_API}/Dec`}
            fullScreen={isMobile}
            formData={_formData}
            labels={labels}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        />
    );
};

const GetDecisionRender = (props: DecisionRenderProps) => <DecisionRender {...props} />;

export default GetDecisionRender;
