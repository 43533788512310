import { Component, useEffect } from 'react';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../redux-base-hooks';

import UtilsString from '../../utils/utils-string';

export interface IEntry {
    to: To;
    options?: NavigateOptions;
}

interface IState {
    history: IEntry[];
    fullHistory: IEntry[];
}

const initialState: IState = {
    history: [],
    fullHistory: []
};

const historyNavigateSlice = createSlice({
    name: 'history-navigate',
    initialState: initialState,
    reducers: {
        append(state: IState, action: PayloadAction<IEntry>) {
            state.history.push(action.payload);
            state.fullHistory.push(action.payload);
        },
        removeLast(state: IState) {
            state.history.pop();
        },
        clear() {
            return initialState;
        }
    }
});

const slice = historyNavigateSlice;

export const historyNavigateReducer = historyNavigateSlice.reducer;

/**
 * Tilføjer start-path til historien nå siden først åbnes
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
export const useInitHistoryNavigate = () => {
    const s = useAppSelector((storeState) => storeState.historyNavigate);
    const d = useAppDispatch();
    useEffect(() => {
        if (s.history.length === 0) {
            const l = window.location;
            d(slice.actions.append({ to: `${l.pathname}${l.search}${l.hash}` }));
        }
    }, [d, s.history.length]);
};

interface IReturnType extends IState {
    /**
     * Navigate to the given url.
     *
     * @param to The url. It is assumed to be a relative path
     * @param options A NavigateOptions object.
     * - Replace: boolean indicating wether "to" is a full url
     * - State: No idea.
     */
    navigate(to: To, options?: NavigateOptions): void;

    /**
     * Navigate back in the app's history, and removes the current route from history. The full route history can still be found as fullHistory
     * @param steps The amount of steps to go back in history. Default 1.
     */
    goBack(steps?: number): void;

    /** useNavigate's navigation function */
    noHistoryNavigate(to: To, options?: NavigateOptions): void;
}

/**
 * Hook for navigating throughout the app, extending react-router's useNavigate with extra functions
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @returns an object with the following elements:
 * - navigate: Navigation function working like useNavigate()(to: To, options?: NavigateOptions)
 * - goBack: Function to go back in history
 * - noHistoryNavigate: The original useNavigate navigation function
 * - history: An array of history objects following the IEntry interface
 * - fullHistory: An array of history objects following the IEntry interface. Unlike history, entries aren't removed from this array when navigating back with goBack()
 */
const useHistoryNavigate = (): IReturnType => {
    const internal_navigate = useNavigate();
    const { history, fullHistory } = useAppSelector((storeState) => storeState.historyNavigate);
    const dispatch = useAppDispatch();

    const navigate = (to: To, options?: NavigateOptions) => {
        dispatch(slice.actions.append({ to, options }));
        internal_navigate(to, options);
    };

    const goBack = (steps = 1) => {
        // If history.length is less than two, someone navigated directly to this page.
        if (history.length < 2) {
            if (UtilsString.IsNullOrWhitespace(document.referrer) || window.location.href === document.referrer)
                navigate('/');
            else window.location.href = document.referrer;
            return;
        }

        // Get path and options
        const { to, options } = history[history.length - steps - 1];

        // Remove entries from history
        for (let i = 0; i < steps; i++) {
            dispatch(slice.actions.removeLast());
        }

        // Navigate
        internal_navigate(to, options);
    };

    return { navigate, goBack, noHistoryNavigate: internal_navigate, history, fullHistory };
};

export default useHistoryNavigate;

export const WithHistoryNavigate = <T extends { historyNavigate: IReturnType }>(C: typeof Component) => {
    // This is a lie, but allows us to render the component
    // const _C = C as unknown as (p: T) => JSX.Element;
    const useWrapper = (p: Omit<T, 'historyNavigate'>) => {
        const h = useHistoryNavigate();
        const _p = { historyNavigate: h, ...p } as T;
        // return <_C {..._p} />;
        return <C {..._p} />;
    };
    return useWrapper;
};
