import { green, red } from '@mui/material/colors';
import { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { CenterContainer } from '../components/mui/styled-mui';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/Error';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export interface ILoadingProps {
    message?: string | JSX.Element;
    /** Set true for a checkmark and false for a cross */
    success?: boolean;
}

const LoadingScreen = (props: ILoadingProps) => {
    const [offsetHeight, setOffsetHeight] = useState(0);
    const messageRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setOffsetHeight(messageRef.current?.offsetHeight ?? 0);
    }, [messageRef]);

    return (
        <CenterContainer>
            <Grid container spacing={2} ref={messageRef}>
                <Grid item xs={12}>
                    {typeof props.message === 'string' ? <Typography>{props.message}</Typography> : props.message}
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ position: 'relative' }}>
                        {props.success !== undefined && (
                            <Fab
                                aria-label={props.success ? 'Success icon' : 'Failure icon'}
                                color={'primary'}
                                sx={{
                                    bgcolor: props.success ? green[500] : red[500],
                                    left: 'calc(50% - 28px)'
                                }}
                            >
                                {props.success ? <CheckIcon /> : <ErrorIcon />}
                            </Fab>
                        )}
                        <CircularProgress
                            size={68}
                            sx={{
                                position: 'absolute',
                                top: -6,
                                left: 'calc(50% - 34px)',
                                zIndex: 1
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ height: `min(${offsetHeight}px, 30vh)` }} />
            </Grid>
        </CenterContainer>
    );
};

export default LoadingScreen;
