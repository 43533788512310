import { Dispatch, createContext, useContext } from 'react';
import { IBaseContext, IBaseProps } from './interfaces';

import useSessionState from '../hooks/extended-hooks/use-session-state';

const TContext = createContext<IBaseContext<unknown>>({
    value: undefined,
    setValue: () => undefined
});

export default function useTContext<T = unknown>() {
    return useContext(TContext) as IBaseContext<T>;
}

export const TProvider = <T,>({ children, defaultValue, storageKey }: IBaseProps<T>) => {
    const [value, setValue] = useSessionState<T>(defaultValue, storageKey);

    return <TContext.Provider value={{ value, setValue: setValue as Dispatch<unknown> }}>{children}</TContext.Provider>;
};
