import { useMemo, useState } from 'react';

export interface IStateObject<T> {
    state: T;
    setState: React.Dispatch<React.SetStateAction<T>>;
}

function useStateObject<T = unknown>(initialState: T | (() => T)) {
    const [state, setState] = useState(initialState);
    const obj = useMemo(
        () => ({
            state,
            setState
        }),
        [state]
    );
    return obj;
}

export default useStateObject;
