import { SFC } from '../../components/map/src/react-controls/search-features/control';
import { safe } from '../utility';

const searchString: typeof SFC.search = (...args) => SFC.search(...args);
const searchIds: typeof SFC.zoomIds = (...args) => SFC.zoomIds(...args);

export const search = {
    searchString: safe(searchString),
    searchIds: safe(searchIds)
};

export default search;
