/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ClickableFeature, ClickableFeatureType } from '../ClickableFeatures';

import { BaseIconId } from '../SVG';
import CTLayer from '../CTLayer';
import Feature from 'ol/Feature';
import { FeatureProperty } from '../feature-utility';
import Geometry from 'ol/geom/Geometry';
import { IMapLayer } from '../../caretaker-map';
import { Options as VOptions } from 'ol/layer/BaseVector';
import VectorSource from 'ol/source/Vector';
import { WKT } from 'ol/format';
import { decodeJsonResponse } from './DataIO';
import { wktFormat } from '../LayerImportExport';

/**
 * @deprecated Use GeometryLayer instead
 */
export class SqlLayer extends CTLayer {
    private static wktFormat = new WKT();
    private wktList: string[];
    public baseIconId: BaseIconId;
    public color!: string;
    private geometryColumnName!: string;
    //@ts-ignore
    private data: string[];

    private endpoint!: string;

    public ResetLayerFeatures() {
        const source = this.getSource();
        source?.clear();
        this.addFeaturesFromData();
        // this.wktList.forEach(wkt => {
        //     source.addFeatures(wktFormat.readFeatures(wkt));
        // })
    }

    public addFeaturesSync(features: Feature<Geometry>[]) {
        this.getSource()?.addFeatures(features);
    }

    private addFeaturesFromData() {
        this.getSource()?.clear();
        this.data.forEach((row) => {
            //@ts-ignore
            if (row[this.geometryColumnName] != null && row[this.geometryColumnName] !== '') {
                //@ts-ignore
                this.wktList.push(row[this.geometryColumnName]);
                //@ts-ignore
                const features = wktFormat.readFeatures(row[this.geometryColumnName]);
                features.forEach((f) => {
                    f.set(FeatureProperty.Data, row);
                    (f as ClickableFeature).dataColumns = [
                        'EnhedSYS',
                        'BygTerSYS',
                        'DelSYS',
                        'JournalSYS',
                        'ObjectID',
                        'ShapeType'
                    ];
                    (f as ClickableFeature).webPage =
                        '/Del/DelMenu/{DelSYS}?enhedId={EnhedSYS}&bygId={BygTerSYS}&caretakerMap=true';
                    (f as ClickableFeature).featureType = ClickableFeatureType.SqlDel;
                });
                this.addFeaturesSync(features);
            }
        });
    }

    public setLoader(endpoint: string, geometryColumnName: string) {
        this.endpoint = endpoint;
        this.geometryColumnName = geometryColumnName;
    }

    public async loader(extent?: any, resolution?: any, projection?: any): Promise<void> {
        try {
            // eslint-disable-next-line no-restricted-syntax
            this.data = await fetch(this.endpoint)
                .then((r) => r.json())
                .then((json) => decodeJsonResponse(json));
        } catch (err) {
            this.data = [];
        }
        //this.data = await fetch(this.endpoint).then(r => r.json()).then(json => decodeJsonResponse(json));
        this.addFeaturesFromData();
    }

    /**
     *
     */
    constructor(options: IMapLayer) {
        options.minZoom = options.minZoom ?? 12;
        options.maxZoom = options.maxZoom ?? 100;

        const vOptions = {
            minZoom: options.minZoom,
            maxZoom: options.maxZoom,
            source: new VectorSource()
        } as VOptions<VectorSource<any>>;
        super(options, vOptions);
        this.baseIconId = options.baseIconId ?? BaseIconId.Tagrende;
        this.wktList = [];
        this.loader = this.loader.bind(this);
        this.addFeaturesSync = this.addFeaturesSync.bind(this);
        //super.getSource().setLoader((extent, resolution, projection) => this.loader(CaretakerMapSettings.get().endpointGetTableAsJson, options.geometryColumn, extent, resolution, projection));
    }

    // (   this: VectorSource<Geometry> | VectorTile,
    //     p0: Extent,
    //     p1: number,
    //     p2: Projection,
    //     p3: (p0: Feature<Geometry>[]) => void,
    //     p4: () => void,
    // )
}
