export enum ControlNames {
    Editing = 'EditingControl',
    GPS = 'GPSControl',
    LayerMenu = 'LayerMenuControl',
    ReportDriftError = 'report-drift-error-control',
    SearchDK = 'SearchDKControl',
    SearchFeatures = 'search-features-control',
    WktIE = 'WktIEControl',
    Playground = 'playground-control'
}
