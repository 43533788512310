import { Func } from '../shared/utils/types';

export const safe =
    <T extends Func<Parameters<T>, ReturnType<T>>>(func: T) =>
    (...args: Parameters<T>) => {
        try {
            return {
                error: null,
                result: func(...args)
            };
        } catch (e: unknown) {
            return {
                error: e,
                result: null
            };
        }
    };

export const safeAsync =
    <T extends Func<Parameters<T>, ReturnType<T>>>(func: T) =>
    async (...args: Parameters<T>) => {
        try {
            return {
                error: null,
                result: await func(...args)
            };
        } catch (e: unknown) {
            return {
                error: e,
                result: null
            };
        }
    };
