import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UserPermissions } from './interfaces';

interface IState {
    permissions?: UserPermissions[];
}

const initialState: IState = {
    permissions: undefined
};

const permSlice = createSlice({
    name: 'perm',
    initialState,
    reducers: {
        setPerm(state: IState, action: PayloadAction<UserPermissions[]>) {
            return { permissions: action.payload };
        }
    }
});

export const permReducer = permSlice.reducer;
