import { Decision, DecisionStepContentProps } from './interfaces';

import { ChangeEvent } from 'react';
import DecisionStepContentWrapper from './decision-step-content-wrapper';
import Divider from '@mui/material/Divider/Divider';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Grid from '@mui/material/Grid/Grid';
import { HorizontalLeftBox } from '../mui/styled-mui';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import Typography from '@mui/material/Typography/Typography';
import UtilsString from '../../shared/utils/utils-string';
import useFetch from '../../shared/hooks/fetch-hooks/use-fetch';

export type { Decision, DecisionStepContentProps } from './interfaces';

const DecisionStepContent = (props: DecisionStepContentProps) => {
    const { url, prevValues, value, setValue, setRefetch } = props;

    // let args = '';
    // if (prevValues.every(p => p !== undefined))
    //     args = prevValues.filter(v => v !== undefined).map(v => v!.id).map(v => typeof (v) === 'number' ? v : (v as string).replace('+', '%2b')).join('+');

    let args;
    if (prevValues.every((p) => p !== undefined))
        args = prevValues.reduce(
            (a, pv, i) => (pv === null ? a : a + `&level${i + 1}=${pv!.id}`),
            `treeid=1&caller=${prevValues.length + 1}`
        );

    const [data, isLoading, isError, , , refetch, hasFetched] = useFetch<Decision>(
        // `${url}?prev=${args}`,
        `${url}?${args}`,
        {
            autoFetch: prevValues.length === 0,
            onSuccessfulFetch: (r) => r.data.data.length === 0 && setValue(null)
        }
    );

    const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(data.data[parseInt(e.target.value)]);
    };

    const getValueDescription = () => {
        if (value == null) return 'Intet valg';

        if (UtilsString.IsNullOrWhitespace(value?.description)) return 'Ingen beskrivelse';

        return value!.description!;
    };

    return (
        <DecisionStepContentWrapper {...{ isLoading, isError, hasFetched, refetch, setRefetch }}>
            {hasFetched && !isError && (
                <>
                    {data.caption !== undefined && <Typography variant='h5'>{data.caption}</Typography>}
                    {data.description !== undefined && <Typography>{data.description}</Typography>}
                    <Divider sx={{ mt: 3, mb: 2 }} />
                    <Typography>{getValueDescription()}</Typography>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <RadioGroup
                        value={value?.id ?? null}
                        onChange={handleRadioChange}
                        row
                        sx={{
                            justifyContent: 'center'
                        }}
                    >
                        <Grid container spacing={2} justifyContent='center'>
                            {data.data?.map((d) => (
                                <Grid key={`choice_${d.id}`} item xs={6} md={4} xl={2}>
                                    <HorizontalLeftBox>
                                        <FormControlLabel
                                            control={<Radio />}
                                            key={`rb-${d.id}`}
                                            value={d.id}
                                            label={
                                                <Typography width={'100%'} align='center'>
                                                    {d.value}
                                                </Typography>
                                            }
                                            sx={{ width: '100%' }}
                                        />
                                    </HorizontalLeftBox>
                                </Grid>
                            ))}
                        </Grid>
                    </RadioGroup>
                </>
            )}
        </DecisionStepContentWrapper>
    );
};

export default DecisionStepContent;
