import { DecisionDataEntry, DecisionTreeProps, DecisionValue } from './interfaces';
import { useCallback, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box/Box';
import DecisionProgressButtons from './decision-progress-buttons';
import DecisionStep from './decision-step';
import DecisionStepContent from './decision-step-content';
import DecisionStepFormContent from './decision-step-form-content';
import { IAutoFormHandle } from '../auto-form/v1/interfaces-and-defaults';
import { Scrollable } from '../misc/flex';
import Step from '@mui/material/Step/Step';
import Stepper from '@mui/material/Stepper/Stepper';
import UtilsString from '../../shared/utils/utils-string';
import useCentralizedSnackbar from '../../shared/hooks/redux-use-centralized-snackbar';

export type { DecisionTreeProps } from './interfaces';

const DecisionTree = (props: DecisionTreeProps<number>) => {
    const { url, labels, onSubmit, onCancel, formData: propFormData, fullScreen } = props;

    const { enqueueSnackbar } = useCentralizedSnackbar();
    const totalSteps = labels.length;
    const [activeStep, setActiveStep] = useState(0);
    const [values, setValues] = useState(Array<DecisionValue>(totalSteps - 1).fill(undefined));
    const [formData, setFormData] = useState<FormData>();
    const formHandle = useRef<IAutoFormHandle>(null);
    const boxRef = useRef<HTMLDivElement>(null);

    /** This */
    const m = useRef({
        refetches: Array<() => void>(totalSteps).fill(() => undefined)
    });

    /** Reset the entire tree */
    const reset = useCallback(() => {
        setActiveStep(0);
        setValues(Array(totalSteps - 1).fill(undefined));
        setFormData(undefined);
        m.current.refetches = Array(totalSteps).fill(() => undefined);
        boxRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }, [totalSteps]);

    /** Gets a function to set the value for a step */
    const getSetValue = (index: number) => (newValue: DecisionDataEntry) => {
        setValues(
            values.map((v, i) => {
                if (i < index) return v;
                if (i === index) {
                    console.log('setting', i, newValue);
                    return newValue;
                }
                console.log('setting', i, undefined);
                return undefined;
            })
        );
    };

    /** Gets a function to set the refetch function for a step */
    const getSetRefetch = (index: number) => (refetch: () => void) => {
        m.current.refetches[index] = refetch;
    };

    /** Get next step missing a value */
    const getNullIndex = useCallback(() => values.findIndex((v) => v == null), [values]);
    const getUndefinedIndex = useCallback(() => values.findIndex((v) => v === undefined), [values]);

    /** Refetch next step when a value changes */
    useEffect(() => {
        if (values[0] === undefined) return;
        const undefinedIndex = getUndefinedIndex();
        const index = undefinedIndex === -1 ? m.current.refetches.length - 1 : undefinedIndex;
        m.current.refetches[index]();
    }, [values, getUndefinedIndex]);

    /** Go to next step */
    const handleNext = useCallback(() => {
        setActiveStep((a) => a + 1);
    }, []);

    /** Go back a step, or back to the last step that had a value assigned */
    const handleBack = () => {
        const nullIndex = getNullIndex();
        const step = nullIndex === -1 ? activeStep : Math.min(nullIndex, activeStep);
        setActiveStep(step - 1);
    };

    /** Cancel form */
    const handleCancel = () => {
        reset();
        onCancel();
    };

    /** Trigger submit of the final step and the subsequent submit to parent component */
    const handleFinished = () => {
        if (formHandle.current == null) {
            setActiveStep(0);
            enqueueSnackbar('Noget gik galt på vores ende. Prøv igen', { variant: 'error' });
            return;
        }

        formHandle.current.submit();
    };

    /** Submit decisions when all data is set */
    useEffect(() => {
        if (formData === undefined) return;

        const data = new FormData();
        // Add values
        // values.map(v => v!.value).forEach((id, index) => data.append(index.toString(), id.toString()));
        // data.append('id', values[values.length - 1]!.id.toString());
        data.append(
            'id',
            values.reduce(
                (lastValue, currentValue) => (currentValue != null ? currentValue.id.toString() : lastValue),
                ''
            )
        );

        // Add values from form
        formData.forEach((v, k) => data.append(k, v));
        // Add values from props
        propFormData?.forEach((f) =>
            UtilsString.IsNullOrWhitespace(f.filename)
                ? data.append(f.name, f.value)
                : data.append(f.name, f.value, f.filename)
        );
        reset();
        onSubmit(data);
    }, [values, formData, propFormData, getNullIndex, onSubmit, reset]);

    /** Scroll to active step */
    useEffect(() => {
        boxRef.current
            ?.querySelector(`s_${activeStep}`)
            ?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }, [activeStep]);

    useEffect(() => {
        if (values[activeStep] === null) {
            handleNext();
        }
    }, [activeStep, handleNext, values]);

    //#region Fullscreen header

    const header_isValueSet = values[activeStep] !== undefined;
    const header_isFirstStep = activeStep === 0;
    const header_isLastStep = activeStep === totalSteps - 1;

    const header = (
        <DecisionProgressButtons
            handleFinished={header_isLastStep ? handleFinished : undefined}
            handleNext={header_isLastStep ? undefined : handleNext}
            handleBack={header_isFirstStep ? undefined : handleBack}
            handleCancel={header_isFirstStep ? handleCancel : undefined}
            buttonProps={{
                next: {
                    disabled: !header_isValueSet
                },
                finished: {
                    disabled: formHandle.current?.allValid === false
                }
            }}
        />
    );

    //#endregion Fullscreen header

    return (
        <Box ref={boxRef} sx={{ height: '100%', pb: 2 }}>
            <Scrollable header={fullScreen ? header : undefined}>
                <Stepper activeStep={activeStep} orientation='vertical'>
                    {Array.from(labels).map((label, index) => {
                        const id = `s_${index}`;
                        const isValueSet = values[index] !== undefined;
                        const isFirstStep = index === 0;
                        const isLastStep = index === totalSteps - 1;
                        return (
                            <Step id={id} key={id}>
                                <DecisionStep
                                    label={label}
                                    value={values[index]}
                                    borderLeft={isLastStep}
                                    progressButtons={
                                        fullScreen ? undefined : (
                                            <DecisionProgressButtons
                                                handleFinished={isLastStep ? handleFinished : undefined}
                                                handleNext={isLastStep ? undefined : handleNext}
                                                handleBack={isFirstStep ? undefined : handleBack}
                                                handleCancel={isFirstStep ? handleCancel : undefined}
                                                buttonProps={{
                                                    next: {
                                                        disabled: !isValueSet
                                                    },
                                                    finished: {
                                                        disabled: formHandle.current?.allValid === false
                                                    }
                                                }}
                                            />
                                        )
                                    }
                                >
                                    {/** isLastStep calculated from index instead, to always have last step correctly rendered */}
                                    {index === totalSteps - 1 ? (
                                        <DecisionStepFormContent
                                            url={url}
                                            prevValues={values}
                                            setRefetch={getSetRefetch(index)}
                                            onSubmit={setFormData}
                                            formHandle={formHandle}
                                        />
                                    ) : (
                                        <DecisionStepContent
                                            url={url}
                                            value={values[index]}
                                            prevValues={values.slice(0, index)}
                                            setValue={getSetValue(index)}
                                            setRefetch={getSetRefetch(index)}
                                        />
                                    )}
                                </DecisionStep>
                            </Step>
                        );
                    })}
                </Stepper>
            </Scrollable>
        </Box>
    );
};

export default DecisionTree;
