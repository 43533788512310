import './index.css';
import './style/fonts/font-import.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { OuterErrorBoundary } from './shared/error-handlers/boundary-generel';
import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
    <>
        {/* <React.StrictMode> */}
        <OuterErrorBoundary>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </OuterErrorBoundary>
        {/* </React.StrictMode> */}
    </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
