import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import { shapeTypes } from './EditingControl';

export enum BaseIconId {
    // Udskift tal med det egentlige koder fra databasen? Kunne være fedt
    Default,
    Enhed,
    Bygning,
    Tagrende,
    Nedløb
}

const _getClusterWidth = (textLength: number) => {
    if (textLength < 3) return 0;

    if (textLength < 5) return 25;

    return Math.max(50, 11 * textLength - 20);
};

const _getClusterAnchor = (clusterWidth: number) => {
    if (clusterWidth === 0) return [0.085, 0.235];

    if (clusterWidth === 25) return [0.123, 0.235];

    if (clusterWidth === 50) return [0.175, 0.235];

    return [clusterWidth / 625 + 0.09, 0.235];
};

const getClusterArgs = (textLength: number) => {
    const width = _getClusterWidth(textLength);
    return {
        width,
        anchor: _getClusterAnchor(width)
    };
};

export class SVG {
    public static createClusterStyle(
        text: string,
        isMatch = true,
        {
            fillColor = '#ff0000',
            strokeColor = '#ffffff',
            unmatchedFillColor = '#808080',
            unmatchedStrokeColor = '#ffffff',
            fontColor = '#fff'
        } = {}
    ) {
        const args = getClusterArgs(text.length);
        const fc = (isMatch ? fillColor : unmatchedFillColor).replace('#', '%23');
        const sc = (isMatch ? strokeColor : unmatchedStrokeColor).replace('#', '%23');

        const svgString = `<?xml version="1.0" encoding="utf-8"?>
        <svg viewBox="0 0 ${args.width + 42} 42"
            height="42px"
            width="${args.width + 42}px"
            xmlns="http://www.w3.org/2000/svg">
            <ellipse style="fill: ${fc}; stroke: ${sc};" cx="21" cy="21" rx="20" ry="20"/>
            <ellipse style="fill: ${fc}; stroke: ${sc};" cx="${args.width + 21}" cy="21" rx="20" ry="20"/>
            <rect x="21" y="1" width="${args.width}" height="40" style="fill: ${fc}; stroke: ${sc};"/>
            <rect x="20" y="2" width="${args.width + 4}" height="38" style="fill: ${fc};"/>
        </svg>`;

        return new Style({
            image: new Icon({
                opacity: 1,
                scale: 0.5,
                src: `data:image/svg+xml;charset=utf8,${svgString}`,
                anchor: [0.5, 0.5]
            }),
            text: new Text({
                text: `${text}`,
                fill: new Fill({
                    color: fontColor
                })
            })
        });
    }

    public static CreateLayerIcon(baseIconId: BaseIconId | shapeTypes, hexColor: string) {
        const ns = 'http://www.w3.org/2000/svg';
        const svg = document.createElementNS(ns, 'svg');
        // svg.style.height = '16px';
        // svg.style.width = '16px';
        // svg.style.fill = hexColor;
        //let svg = document.createElement('svg');
        svg.setAttribute('height', '16');
        svg.setAttribute('width', '16');
        svg.setAttribute('fill', hexColor);
        switch (baseIconId) {
            case BaseIconId.Tagrende:
                const path1 = document.createElementNS(ns, 'path'); // <svg height="16" width="16" fill="${hexColor}"><path></path></svg>
                const transform1 = 'rotate(78 6.1851 8.01383)';
                const d1 =
                    'm9.05591,5.18316l-2.67927,2.83084l2.67927,2.83083l-1.53118,1.61876l-4.21045,-4.44959l4.21045,-4.44993';
                path1.setAttribute('d', d1);
                path1.setAttribute('transform', transform1);
                svg.appendChild(path1);

                const path2 = document.createElementNS(ns, 'path');
                const transform2 = 'rotate(-105 9.896 8.05746)';
                const d2 =
                    'm12.98952,5.33369l-2.88712,2.72393l2.88712,2.72393l-1.64995,1.55763l-4.53708,-4.28155l4.53708,-4.28188';
                path2.setAttribute('d', d2);
                path2.setAttribute('transform', transform2);
                svg.appendChild(path2);
                break;
            case BaseIconId.Nedløb:
                const circle1 = document.createElementNS(ns, 'circle');
                circle1.setAttribute('cx', '8');
                circle1.setAttribute('cy', '8');
                circle1.setAttribute('r', '6');
                svg.appendChild(circle1);
                break;
            case BaseIconId.Bygning:
                const polygon1 = document.createElementNS(ns, 'polygon');
                polygon1.setAttribute('points', '4.5,1 11.5,1 13.66,7.66 8,11.77 2.34,7.66');
                polygon1.setAttribute('transform', 'rotate(180, 8, 8) translate(0, 2.5)');
                svg.appendChild(polygon1);

                const polygon2 = document.createElementNS(ns, 'polygon');
                polygon2.setAttribute('points', '4.5,1 11.5,1 13.66,7.66 8,11.77 2.34,7.66');
                polygon2.setAttribute('transform', 'rotate(180, 8, 8) scale(0.5) translate(8, 5.82) translate(0, 5)');
                polygon2.setAttribute('fill', '#FFFFFF');
                svg.appendChild(polygon2);
                break;
            case BaseIconId.Enhed:
                const circle = document.createElementNS(ns, 'circle');
                circle.setAttribute('cx', '8');
                circle.setAttribute('cy', '8');
                circle.setAttribute('r', '6');
                svg.appendChild(circle);
                break;
            case shapeTypes.Point:
                const circle2 = document.createElementNS(ns, 'circle');
                circle2.setAttribute('cx', '8');
                circle2.setAttribute('cy', '8');
                circle2.setAttribute('r', '6');
                svg.appendChild(circle2);
                break;
            case shapeTypes.Line:
                const path3 = document.createElementNS(ns, 'path'); // <svg height="16" width="16" fill="${hexColor}"><path></path></svg>
                const transform3 = 'rotate(78 6.1851 8.01383)';
                const d3 =
                    'm9.05591,5.18316l-2.67927,2.83084l2.67927,2.83083l-1.53118,1.61876l-4.21045,-4.44959l4.21045,-4.44993';
                path3.setAttribute('d', d3);
                path3.setAttribute('transform', transform3);
                svg.appendChild(path3);

                const path4 = document.createElementNS(ns, 'path');
                const transform4 = 'rotate(-105 9.896 8.05746)';
                const d4 =
                    'm12.98952,5.33369l-2.88712,2.72393l2.88712,2.72393l-1.64995,1.55763l-4.53708,-4.28155l4.53708,-4.28188';
                path4.setAttribute('d', d4);
                path4.setAttribute('transform', transform4);
                svg.appendChild(path4);
                break;
            case shapeTypes.Polygon:
                const polygon3 = document.createElementNS(ns, 'polygon');
                polygon3.setAttribute('points', '4.5,1 11.5,1 13.66,7.66 8,11.77 2.34,7.66');
                polygon3.setAttribute('transform', 'rotate(180, 8, 8) translate(0, 2.5)');
                svg.appendChild(polygon3);

                const polygon4 = document.createElementNS(ns, 'polygon');
                polygon4.setAttribute('points', '4.5,1 11.5,1 13.66,7.66 8,11.77 2.34,7.66');
                polygon4.setAttribute('transform', 'rotate(180, 8, 8) scale(0.5) translate(8, 5.82) translate(0, 5)');
                polygon4.setAttribute('fill', '#FFFFFF');
                svg.appendChild(polygon4);
                break;
            case BaseIconId.Default:
            default:
                break;
        }
        return svg;
    }
}
