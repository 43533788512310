/* eslint-disable react/jsx-pascal-case */
//#region Interfaces

import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { EventInfo, NativeEventInfo } from 'devextreme/events';
import { IItemProps, ItemTypes } from './interfaces-and-defaults';
import TextField, { TextFieldProps } from '@mui/material/TextField/TextField';

import Box from '@mui/material/Box/Box';
import { CenterCenterBox } from '../../mui/styled-mui';
import DateBox from 'devextreme-react/date-box';
import FileUploader from 'devextreme-react/file-uploader';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Grid from '@mui/material/Grid/Grid';
import NumberFormat from 'react-number-format';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import UtilsString from '../../../shared/utils/utils-string';
import { ValueChangedInfo } from 'devextreme/ui/editor/editor';
import __ from '../../../shared/utils/lodash-expansions';
import dxDateBox from 'devextreme/ui/date_box';

/** Styled TextField. The styled function wouldn't compile it with npm run build */
const AltStyledTextField = (props: TextFieldProps) => {
    const dProps: TextFieldProps = {
        fullWidth: true,
        sx: {
            '& div.MuiOutlinedInput-root.MuiInputBase-root': {
                color: 'rgba(0, 0, 0, 0.87)',

                '& .MuiOutlinedInput-input.MuiInputBase-input': {
                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)'
                }
            },
            '& fieldset legend': props.required
                ? {}
                : {
                      width: '0px'
                  }
        }
    };

    return <TextField {...__.deepMerge(dProps, props)} />;
};
export const FormTextField = (props: TextFieldProps) => <AltStyledTextField variant='outlined' fullWidth {...props} />;
const FormTextField_Number = (props: TextFieldProps) => (
    <FormTextField inputProps={{ pattern: '[0-9]', type: 'numeric', inputMode: 'numeric' }} {...props} />
);
const FormTextField_Decimal = (props: TextFieldProps) => (
    <FormTextField inputProps={{ pattern: '[0-9]', inputMode: 'decimal' }} {...props} />
);

//#region Custom items

//#region Conditional text block

export interface IComboConditionValue {
    choice: string | number;
    text: string;
}

const Custom_Combo_ConditionalBlock = ({
    item,
    generalInputProps,
    validation,
    scrollRef
}: IItemProps<IComboConditionValue>) => {
    const c0 = useMemo(() => item.choices?.[0] ?? { id: 'true', value: 'Ja' }, [item.choices]);
    const c1 = item.choices?.[1] ?? { id: 'false', value: 'Nej' };
    const [choice, setChoice] = useState<IComboConditionValue['choice'] | undefined>(item.value?.choice ?? c1.id);

    const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
        setChoice((e.target as HTMLInputElement).value);

        // Er der valgt 'Nej' er det validt, ellers sættes valid false, fordi der skal skrives i boksen
        // Boxen er nødvendigvis tom på dette tidspunkt, da den først mountes næste render
        validation.setValid((e.target as HTMLInputElement).value === c1.id);
    };

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) =>
        validation.setValid(!UtilsString.IsNullOrWhitespace(e.target.value));

    useEffect(() => {
        if (choice === c0.id)
            document
                .getElementById(`fc-${item.id}`)
                ?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }, [choice, c0, item.id]);

    return (
        <>
            <RadioGroup
                value={choice}
                onChange={handleRadioChange}
                name={`${item.id}-choice`}
                row
                sx={{
                    justifyContent: 'center'
                }}
            >
                <FormControlLabel key={`${item.id}-${c0.id}`} value={c0.id} control={<Radio />} label={c0.value} />
                <FormControlLabel key={`${item.id}-${c1.id}`} value={c1.id} control={<Radio />} label={c1.value} />
            </RadioGroup>
            {choice === c0.id && (
                <FormTextField
                    placeholder='Tekst'
                    {...__.copyOmit(generalInputProps, 'helperText')}
                    helperText={
                        validation?.showError && !validation?.isValid
                            ? 'Er denne box åben, skal den udfyldes'
                            : undefined
                    }
                    multiline
                    type='text'
                    defaultValue={item.value?.text}
                    required
                    onChange={handleTextChange}
                />
            )}
        </>
    );
};

//#endregion Conditional text block
//#region Date box

const Custom_DxDateBox = ({ item, generalInputProps, validation }: IItemProps) => {
    const [editing, setEditing] = useState(false);
    const [value, _setValue] = useState<string>(item.value?.toString() ?? '');
    const setValue = (val: typeof value) => {
        validation.setValid(!UtilsString.IsNullOrWhitespace(val));
        _setValue(val);
    };

    // Key index bruges så react tror datebox er et nyt element i renders efter det ændres
    // Det er for at tvinge DxDateBox til at sætte sit input felt til {value} hver gang
    //     Bruges dette ikke, kan man skrive en halv dato og skifte ud af feltet, uden {value} ændres,
    //     Det flytter required (*) ned oveni den halve dato tekst
    const [keyToggle, setKeyToggle] = useState(true);

    // useLayoutEffect(() => {
    //     validation.setValid(!UtilsString.IsNullOrWhitespace(value));
    // }, [value])

    //#region Event handlers

    const handleValueChanged = (e: NativeEventInfo<dxDateBox> & ValueChangedInfo) => setValue(e.value ?? '');

    const handleFocusIn = (_e: NativeEventInfo<dxDateBox>) => setEditing(true);

    const handleFocusOut = (e: NativeEventInfo<dxDateBox>) => {
        setEditing(false);
        setKeyToggle(!keyToggle);
    };

    const handleClosed = (e: EventInfo<dxDateBox>) => e.component.blur();

    //#endregion Event handlers

    // Label is a copy of the label from mui's textfield, with a bit of added style (.replica-mui-label) to make it work.
    // replica-mui-label-(x) changes background and size of the label
    // The latter two classes in each case, determine the position and animation.
    const asteriskPosition =
        editing || value !== ''
            ? 'mui-label-outside replica-mui-label-outside'
            : 'mui-label-inside replica-mui-label-inside';
    const color = validation?.showError && !validation?.isValid ? 'red' : undefined;

    return (
        <Box maxHeight='56px'>
            {/* Replica label */}
            {item.required && !item.readonly && (
                <label
                    className={`${asteriskPosition} replica-mui-label-base mui-label-base`}
                    data-shrink='true'
                    htmlFor={`${item.id}`}
                    id={`${item.id}-label`}
                >
                    <span aria-hidden='true' style={{ color }}>
                        *
                    </span>
                </label>
            )}
            {/* The date picker */}
            <DateBox
                key={`date-box-${item.id}-${keyToggle ? '0' : '1'}`}
                defaultValue={value}
                type={'date'}
                className='dx-date-box-as-mui'
                dateSerializationFormat='yyyy-MM-dd'
                acceptCustomValue={false}
                // openOnFieldClick={true}
                onValueChanged={handleValueChanged}
                onFocusIn={handleFocusIn}
                onFocusOut={handleFocusOut}
                onClosed={handleClosed}
                // buttons={['clear', 'dropDown']}
                // displayFormat="shortdate"
                displayFormat='dd/MM/yyyy'
                readOnly={item.readonly ?? undefined}
            />
            {/* Helpertext */}
            <p className='helper-text' style={{ color }}>
                {generalInputProps.helperText}
            </p>

            {/* Hidden input field to submit the date */}
            <FormTextField
                sx={{ visibility: 'hidden', height: '0px' }}
                value={value}
                {...__.copyOmit(generalInputProps, 'defaultValue')}
            />
        </Box>
    );
};

//#endregion Date box
//#region File uploader

const Custom_DxFileUploader = ({ item, generalInputProps, validation, scrollRef }: IItemProps) => {
    if (generalInputProps.disabled) return <></>;

    return <FileUploader name={generalInputProps.name} multiple labelText='' uploadMode='useForm' />;
};

//#endregion File uploader

//#region Combined

const Custom_Combined = (props: IItemProps<any>) => {
    return (
        <>
            {props.item.serviceLayout ? (
                <FormItemRadio {...props} />
            ) : (
                <RadioGroup
                    defaultValue={props.item.value}
                    name={props.item.id}
                    row
                    sx={{
                        justifyContent: 'left'
                    }}
                >
                    {props.item.choices?.map((c) => (
                        <FormControlLabel
                            key={`fc-rg-i-${c.id}`}
                            value={c.id}
                            control={<Radio />}
                            label={c.value}
                            sx={{ width: '100%' }}
                        />
                    ))}
                </RadioGroup>
            )}

            <FormTextField
                {...props.generalInputProps}
                name={`${props.item.id}-text`}
                type='text'
                defaultValue={props.item.valueText}
                multiline={true}
                maxRows={5}
                key={`${props.item.id}-text`}
                required={props.item.serviceLayout ? false : props.generalInputProps.required}
            />
        </>
    );
};

//#endregion Combined

const FormItemRadio = ({ item, validation }: IItemProps<any>) => {
    const [value, setValue] = useState(item.value ?? null);
    const prevRatingnumeric = item.prev ?? -999;

    const handleOnClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        if (item.readonly ?? false) return;
        const n = Number((e.target as any).value);
        const newValue = n === value ? null : n;
        // (e.target as any).value = newValue;
        setValue(newValue);
        validation.setValid(!item.required ? true : newValue !== null ? true : false);
    };

    return (
        // <CenterCenterBox>
        <FormControl sx={{ width: '100%' }}>
            <RadioGroup name={String(item.id)} value={value}>
                <Grid container columns={item.choices?.length ?? 1}>
                    {item.choices?.map((choice) => {
                        return (
                            <Grid key={choice.id} item xs={'auto'}>
                                <CenterCenterBox
                                    sx={{ backgroundColor: prevRatingnumeric === choice.id ? 'lightblue' : 'unset' }}
                                >
                                    <FormControlLabel
                                        value={choice.id}
                                        control={<Radio onClick={handleOnClick} required={item.required ?? false} />}
                                        label={choice.value}
                                        labelPlacement={'end'}
                                        sx={{ ml: 2, mr: 2 }}
                                        // disabled={item.readOnly && ratingnumeric !== choice}
                                    />
                                </CenterCenterBox>
                            </Grid>
                        );
                    })}
                </Grid>
            </RadioGroup>
        </FormControl>
        // </CenterCenterBox>
    );
};

//#endregion Custom items

const generateFormItem = (props: IItemProps<any>): JSX.Element => {
    switch (props.item.type) {
        //#region Base items
        default:
        case ItemTypes.String:
            return <FormTextField {...props.generalInputProps} type='text' multiline={true} maxRows={10} />;
        case ItemTypes.Block:
            return <FormTextField {...props.generalInputProps} multiline type='text' />;
        case ItemTypes.Number:
            return <NumberFormat {...props.generalInputProps} customInput={FormTextField_Number} decimalScale={0} />;
        case ItemTypes.Decimal:
            const df = ((props.generalInputProps.defaultValue as string) ?? '0').replace('.', ',');
            return (
                <NumberFormat
                    {...props.generalInputProps}
                    defaultValue={df}
                    customInput={FormTextField_Decimal}
                    decimalSeparator=','
                    decimalScale={2}
                />
            );
        case ItemTypes.Choice:
            if (props.item.serviceLayout) {
                return <FormItemRadio {...props} />;
            }
            return (
                <RadioGroup
                    defaultValue={props.item.value}
                    name={props.item.id}
                    row
                    sx={{
                        justifyContent: 'left'
                    }}
                >
                    {props.item.choices?.map((c) => (
                        <FormControlLabel
                            key={`fc-rg-i-${c.id}`}
                            value={c.id}
                            control={<Radio />}
                            label={c.value}
                            sx={{ width: '100%' }}
                        />
                    ))}
                </RadioGroup>
            );
        case ItemTypes.NoInput:
            return (
                <></>
                // <Typography variant='h4'>{props.item.value}</Typography>
            );
        //#endregion Base items
        //#region Combo items
        case ItemTypes.File:
            return <Custom_DxFileUploader {...props} />;
        case ItemTypes.Date:
            return <Custom_DxDateBox {...props} />;
        case ItemTypes.Combo_ConditionalBlock:
            return <Custom_Combo_ConditionalBlock {...props} />;
        case ItemTypes.Combined:
            return <Custom_Combined {...props} />;
        //#endregion Combo items
    }
};

export default generateFormItem;
