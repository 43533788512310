import { GroupItem, SimpleItem } from 'devextreme-react/form';
import { IDictionary, PropsOf } from '../../../../shared/utils/types';
import { IInput, IItem, ItemTypes } from '../interfaces';
import { RequiredRule, StringLengthRule } from 'devextreme-react/validator';

import InputBlock from './input-block';
import InputChoice from './input-choice';
import InputDate from './input-date';
import InputDecimal from './input-decimal';
import InputDefault from './input-default';
import InputFile from './input-file';
import InputLookup from './input-lookup';
import InputNoInput from './input-no-input';
import InputNumber from './input-number';
import InputString from './input-string';
import { ReactNode } from 'react';
import __ from '../../../../shared/utils/lodash-expansions';
import { isInput } from '../type-guards';

/** Generate a form item */
export const generateItem = (item: IItem, isEditing?: boolean) => {
    if (isEditing && item.hideOnEdit) return null;

    const getId = (item: IItem, level = 0): string =>
        isInput(item) ? item.id + `g${level}` : getId(item.items[0], ++level);

    if (isInput(item)) return generateInput({ ...item, readOnly: item.readOnly || !isEditing });

    return (
        <GroupItem
            key={getId(item)}
            caption={item.label}
            colCount={item.colCount}
            colCountByScreen={item.colCountByScreen}
            colSpan={item.colSpan}
        >
            {item.items.map((i) => generateItem(i, isEditing))}
        </GroupItem>
    );
};

/** Generate a form input */
export const generateInput = (input: IInput) => inputs[input.type ?? ItemTypes.Default](input);

/** Dictionary of all inputs */
const inputs: IDictionary<(input: IInput) => ReactNode, ItemTypes> = {
    [ItemTypes.String]: InputString,
    [ItemTypes.Block]: InputBlock,
    [ItemTypes.NoInput]: InputNoInput,
    [ItemTypes.Number]: InputNumber,
    [ItemTypes.Decimal]: InputDecimal,
    [ItemTypes.Date]: InputDate,
    [ItemTypes.Choice]: InputChoice,
    [ItemTypes.File]: InputFile,
    [ItemTypes.Combo_ConditionalBlock]: InputDefault,
    [ItemTypes.Combined]: InputDefault,
    [ItemTypes.Lookup]: InputLookup,

    [ItemTypes.Default]: InputDefault
};

export const generateProps = (input: IInput, defaultInput?: Partial<IInput>): PropsOf<SimpleItem> & { key: string } => {
    const _input = __.deepMerge(defaultInput, input);

    return {
        key: _input.id,
        colSpan: _input.colSpan,
        dataField: _input.id,
        editorType: _input.editorType,
        editorOptions: {
            readOnly: _input.readOnly,
            items: _input.choices,
            ..._input.editorOptions
        },
        helpText: _input.helpText,
        label: {
            text: _input.label ?? _input.id[0].toUpperCase() + _input.id.substring(1)
        }
    };
};

export const generateRules = (input: IInput, defaultInput?: Partial<IInput>) => {
    const props = __.deepMerge(defaultInput, input);

    const rules: JSX.Element[] = [];

    if (props.required) rules.push(<RequiredRule key='required' />);

    if (props.maxLength !== undefined) rules.push(<StringLengthRule key='string length' max={props.maxLength} />);

    return rules;
};
