import Box from '@mui/material/Box';
import { ControlHandler } from '../../ControlHandler';
import CreateBaseLayerGui from './create-base-layer-gui';
import CreateLayerGui from './create-layer-gui';
import { Layers } from '../../LayerMenuControl';
import { createRoot } from 'react-dom/client';

export interface ICreateGui {
    layers: Layers;
    controlHandler: ControlHandler;
}

const CreateGuiElements = ({ ...props }: ICreateGui) => {
    return (
        <Box sx={{ overflow: 'auto', maxHeight: '80vh', width: 'calc(100% + 5px)' }}>
            <CreateBaseLayerGui {...props} />
            <CreateLayerGui {...props} />
        </Box>
    );
};

const GetCreateGuiElements = (layers: Layers, controlHandler: ControlHandler) => {
    const div = document.createElement('div');
    const root = createRoot(div);
    root.render(<CreateGuiElements {...{ layers, controlHandler }} />);

    return div;
};

export default GetCreateGuiElements;
