import useDictionary, { useDictionaryKeys } from '../../shared/hooks/redux-use-dictionary';

import { CSSProperties } from 'react';
import UtilsString from '../../shared/utils/utils-string';

/**
 * The actual HTML element rendering the map in \<CaretakerMap />.
 * When shown, its css is altered to be shown exactly on top of the div rendered by <CaretakerMap />
 *
 * @returns A div targeted by CaretakerMap
 */
const MapPortal = () => {
    const [currentMapKey] = useDictionary(useDictionaryKeys.MapActive, '');
    const [mapCss] = useDictionary(useDictionaryKeys.MapCss, '{}');
    const styleObj: CSSProperties = JSON.parse(mapCss);

    return (
        <>
            <div
                style={{
                    display: UtilsString.IsNullOrWhitespace(currentMapKey) ? 'none' : 'block',
                    position: 'absolute',
                    ...styleObj
                }}
            >
                <div id='map-root-container'>
                    <div id={`map-root`} className='map-root' />

                    <div
                        id='testButtonControl'
                        className='testButtonControl'
                        // style={{ display: `${false ? 'block' : 'none'}` }}
                    >
                        <div className='controll'>
                            <p>zoom: </p>
                            <p id='zoomNiveau'>0000</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MapPortal;
