import { BaseIconId } from '../../SVG';
import ReactDOMServer from 'react-dom/server';
import { SVGProps } from 'react';
import getTextWidth from '../../../../../shared/utils/text-width';
import { shapeTypes } from '../../EditingControl';

const DefaultSvg = ({ hexColor, props }: { hexColor: string; props?: SVGProps<SVGSVGElement> }) => {
    return <svg height={'16'} width={'16'} fill={hexColor} {...props} />;
};

// Craetes cirkle/point, line and polygon svg's
const CreateLayerIcon = (baseIconId: BaseIconId | shapeTypes, hexColor: string) => {
    switch (baseIconId) {
        case BaseIconId.Tagrende:
        case shapeTypes.Line:
            return (
                <DefaultSvg
                    hexColor={hexColor}
                    props={{
                        children: (
                            <>
                                <path
                                    transform='rotate(78 6.1851 8.01383)'
                                    d='m9.05591,5.18316l-2.67927,2.83084l2.67927,2.83083l-1.53118,1.61876l-4.21045,-4.44959l4.21045,-4.44993'
                                />
                                <path
                                    transform='rotate(-105 9.896 8.05746)'
                                    d='m12.98952,5.33369l-2.88712,2.72393l2.88712,2.72393l-1.64995,1.55763l-4.53708,-4.28155l4.53708,-4.28188'
                                />
                            </>
                        )
                    }}
                />
            );
        case BaseIconId.Nedløb:
        case BaseIconId.Enhed:
        case shapeTypes.Point:
            return <DefaultSvg hexColor={hexColor} props={{ children: <circle cx={'8'} cy={'8'} r={'6'} /> }} />;
        case BaseIconId.Bygning:
        case shapeTypes.Polygon:
            return (
                <DefaultSvg
                    hexColor={hexColor}
                    props={{
                        children: (
                            <>
                                <polygon
                                    points='4.5,1 11.5,1 13.66,7.66 8,11.77 2.34,7.66'
                                    transform='rotate(180, 8, 8) translate(0, 2.5)'
                                />
                                <polygon
                                    points='4.5,1 11.5,1 13.66,7.66 8,11.77 2.34,7.66'
                                    transform='rotate(180, 8, 8) scale(0.5) translate(8, 5.82) translate(0, 5)'
                                    fill='#FFFFFF'
                                />
                            </>
                        )
                    }}
                />
            );
        default:
            return <DefaultSvg hexColor={hexColor} />;
    }
};

// Used in the gui because it is react
export const TextSVG = ({ text, hexColor }: { text: string; hexColor?: string }) => {
    const textOptions: Parameters<typeof getTextWidth>[1] = {
        fontSize: '10',
        fontFamily: 'sans-serif',
        fontWeight: '400'
    };

    const width = getTextWidth(text, textOptions) + 8;

    return (
        <svg width={width} height='13' xmlns='http://www.w3.org/2000/svg'>
            <rect width={width} height='100%' stroke={hexColor} fill={hexColor} />
            <text
                x='50%'
                y='60%'
                dominantBaseline='middle'
                textAnchor='middle'
                fill='rgba(255,255,255,1)'
                {...textOptions}
            >
                {text}
            </text>
        </svg>
    );
};

export const StringTextSVG = (text: string, hexColor?: string) =>
    ReactDOMServer.renderToStaticMarkup(<TextSVG text={text} hexColor={hexColor && hexColor.replace('#', '%23')} />);

export default CreateLayerIcon;
