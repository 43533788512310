import Box from '@mui/material/Box/Box';
import { DecisionStepProps } from './interfaces';
import Divider from '@mui/material/Divider/Divider';
import StepContent from '@mui/material/StepContent/StepContent';
import StepLabel from '@mui/material/StepLabel/StepLabel';

export type { DecisionStepProps } from './interfaces';
const DecisionStep = ({ label: _label, value, children, progressButtons }: DecisionStepProps) => {
    const { label = 'Intet valg', description } = _label;
    return (
        <>
            <StepLabel optional={description}>
                {value !== null ? (value !== undefined ? value.value : label) : 'Automatisk udfyldt'}
            </StepLabel>
            <StepContent
                TransitionProps={{ unmountOnExit: false }}
                transitionDuration={500}
                sx={{
                    borderLeft: '1px solid #bdbdbd'
                }}
            >
                <Box
                    sx={{
                        minHeight: '178px',
                        width: 1,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box>{children}</Box>
                    {progressButtons && (
                        <Box
                            sx={{
                                marginTop: 'auto',
                                pb: 2
                            }}
                        >
                            <Divider sx={{ mt: 2, mb: 2 }} />
                            {progressButtons}
                        </Box>
                    )}
                </Box>
            </StepContent>
        </>
    );
};

export default DecisionStep;
