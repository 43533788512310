import * as React from 'react';

import BurgerMenuList, { BurgerMenuListItem } from './burger-menu-list';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import Box from '@mui/material/Box';
import { BreadCrumbContext } from '../../shared/contexts/breadcrumb-context';
import Breadcrumbs from '@mui/material/Breadcrumbs/Breadcrumbs';
import CachedIcon from '@mui/icons-material/Cached';
import { CenterLeftBox } from '../mui/styled-mui';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { FlexColumn } from '../misc/flex';
import GetAppIcon from '@mui/icons-material/GetApp';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link/Link';
import List from '@mui/material/List/List';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import Restricted from '../restricted/restricted';
import SettingsIcon from '@mui/icons-material/Settings';
import SwipeableDrawer from '@mui/material/SwipeableDrawer/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import { UserPermissions } from '../restricted/interfaces';
import { defferedPrompt } from '../../serviceWorkerRegistration';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../shared/hooks/redux-use-user';
import useBreakpoints from '../../shared/hooks/use-breakpoints';
import { useContext } from 'react';
import useHistoryNavigate from '../../shared/hooks/extended-hooks/use-history-navigate';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

const BreadCrumb = (value: string | string[], navigate?: string) => {
    if (typeof value === 'string') {
        return (
            <Link key={value} underline='hover' sx={{ display: 'flex', alignItems: 'center' }} color='inherit'>
                {/* <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
                {value}
            </Link>
        );
    }
    return (
        <Link
            key={value[0]}
            underline='hover'
            sx={{ display: 'flex', alignItems: 'center' }}
            color='inherit'
            // href={navigate?.[1]}
            onClick={() => (window.location.href = value[1])}
        >
            {/* <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
            {value[0]}
        </Link>
    );
};

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
}));

interface IDraverProps {
    children: React.ReactNode | React.ReactNode[];
}

export default function PersistentDrawerLeft({ children }: IDraverProps) {
    const [open, setOpen] = React.useState(false);
    const { navigate } = useHistoryNavigate();
    const { signOut } = useAuth();
    const { value } = useContext(BreadCrumbContext);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const { md } = useBreakpoints();

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Box sx={{ display: 'block', width: '100%', height: '100%', background: 'white' }}>
                {/* <Box sx={{ display: 'flex' }}> */}
                <CssBaseline />
                <AppBar position='fixed' open={open}>
                    <Toolbar>
                        <IconButton
                            color='inherit'
                            aria-label='open drawer'
                            onClick={handleDrawerOpen}
                            edge='start'
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <CenterLeftBox>
                            <Breadcrumbs sx={{ color: '#474646', fontWeight: 600, ...(open && { display: 'none' }) }}>
                                <Link
                                    key={''}
                                    underline='hover'
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color='inherit'
                                    onClick={() => navigate('.')}
                                >
                                    <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                                    Hjem
                                </Link>
                                {value.map((val) => BreadCrumb(val))}
                            </Breadcrumbs>
                        </CenterLeftBox>
                        <IconButton onClick={() => navigate('/')} sx={{ ...(!md && { display: 'none' }) }}>
                            <img src='/images/ct-logo-text-scale.svg' alt='Caretaker Logo' width='150px' />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <SwipeableDrawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box'
                        },
                        zIndex: 1600
                    }}
                    disableSwipeToOpen
                    variant='temporary'
                    anchor='left'
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                >
                    <FlexColumn
                        header={
                            <>
                                <DrawerHeader sx={{ p: '0px' }}>
                                    <List disablePadding sx={{ width: '100%' }}>
                                        <BurgerMenuListItem
                                            tekst='Hjem'
                                            path='.'
                                            close={handleDrawerClose}
                                            icon={<HomeIcon />}
                                        />
                                    </List>
                                </DrawerHeader>
                                <Divider />
                            </>
                        }
                        footer={
                            <List>
                                <Restricted permissions={UserPermissions.Settings}>
                                    <BurgerMenuListItem
                                        tekst='Indstillinger'
                                        path='/Settings'
                                        close={handleDrawerClose}
                                        icon={<SettingsIcon />}
                                    />
                                </Restricted>
                                <BurgerMenuListItem
                                    onClick={() => {
                                        window.location.href = window.location.href + '?_=' + Date.now();
                                    }}
                                    tekst='Genstart App'
                                    icon={<CachedIcon />}
                                />
                                {defferedPrompt != null && (
                                    <BurgerMenuListItem
                                        onClick={() => defferedPrompt?.prompt()}
                                        tekst='Download app'
                                        icon={<GetAppIcon />}
                                    />
                                )}
                                <Divider />
                                <BurgerMenuListItem
                                    onClick={signOut}
                                    tekst='Log ud'
                                    close={handleDrawerClose}
                                    icon={<LogoutIcon />}
                                />
                            </List>
                        }
                    >
                        <BurgerMenuList close={handleDrawerClose} />
                    </FlexColumn>
                </SwipeableDrawer>
                <Box>
                    <DrawerHeader />
                    {children}
                </Box>
            </Box>
        </>
    );
}
