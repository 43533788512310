import { DataforsyningenBygning, bbruuid } from '../../components/map/src/react-controls/playground/interfaces';
import { Feature, MapBrowserEvent } from 'ol';

import axios from 'axios';
import { map } from '../../components/map/src';
import { mapEventTarget } from '../../components/map/src';
import { safeAsync } from '../utility';

type id = string | number;

type clickListener = (e: MapBrowserEvent<UIEvent>) => void;
type featureListener = (features: Feature[]) => void;
type listener = clickListener | featureListener;
let clickListeners: clickListener[] = [];
let featureListeners: featureListener[] = [];

mapEventTarget.addEventListener('init', () => {
    // Reset on map change
    clickListeners = [];
    featureListeners = [];

    // test();

    map!.addEventListener('singleclick', (e) => {
        clickListeners.forEach((l) => l(e as MapBrowserEvent<UIEvent>));
        clickListeners = [];

        const features = map!
            .getFeaturesAtPixel((e as MapBrowserEvent<UIEvent>).pixel)
            .filter((f) => f.getId() !== undefined) as Feature[];

        if (features.length === 0) return;

        featureListeners.forEach((l) => l(features));
        featureListeners = [];
    });
});

const addListener = async <T, L extends listener>(a: L[], c: (x: Parameters<L>[0]) => T) =>
    await new Promise<T>((resolve) => a.push(((x: Parameters<L>[0]) => resolve(c(x))) as L));

const addClickListener = async <T>(c: (e: MapBrowserEvent<UIEvent>) => T) => await addListener(clickListeners, c);
const addFeatureListener = async <T>(c: (f: Feature[]) => T) => await addListener(featureListeners, c);

export const getNextClick = async () => await addClickListener((e) => e);

/**
 * Waits for a building to be clicked before getting its BBR UUID from dataforsyningen
 */
export const getNextBBRUUIDClick = async (): Promise<bbruuid | null> => {
    const e = await getNextClick();

    const [x, y] = e.coordinate;
    try {
        const response = await axios.get<DataforsyningenBygning[]>(
            `https://api.dataforsyningen.dk/bbrlight/bygninger?srid=25832&x=${x}&y=${y}`
        );
        const [realX, realY] = response.data[0].bygningspunkt.koordinater;
        const dx = Math.abs(x - realX),
            dy = Math.abs(y - realY);
        const distance = Math.sqrt(dx * dx + dy * dy);

        return {
            uuid: response.data[0].Bygning_id,
            clickCoordinate: [x, y],
            bbrCoordinate: [realX, realY],
            distance
        };
    } catch {
        return null;
    }
};

/**
 * Get the next feature that is clicked
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @returns A promise resolving when a feature is clicked
 */
export const getNextFeatureClick = async () => await addFeatureListener((features) => features);

/**
 * Get the id of the next feature that is clicked
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @returns A promise resolving when a feature is clicked
 */
const awaitFeatureClick = async (): Promise<id[]> =>
    await addFeatureListener((features) => features.map((f) => f.getId()!));

export const features = {
    awaitFeatureClick: safeAsync(awaitFeatureClick),
    getNextFeatureClick: safeAsync(getNextFeatureClick),
    getNextBBRUUIDClick: safeAsync(getNextBBRUUIDClick)
};

export default features;
