import Box from '@mui/material/Box';
import Button from 'devextreme-react/button';
import { IActionsProps } from './interfaces';

const Actions = ({
    actions,
    allowEditing,
    isEditing,
    triggerBack,
    triggerCancel,
    triggerEdit,
    triggerSubmit
}: IActionsProps) => {
    if (isEditing)
        return (
            <Box sx={{ pb: '10px' }}>
                {actions.includes('submit') && (
                    <Button text='Gem' type='success' onClick={triggerSubmit} style={{ marginRight: '10px' }} />
                )}
                {actions.includes('cancel') && <Button text='Annuller' onClick={triggerCancel} />}
            </Box>
        );

    return (
        <Box sx={{ pb: '10px' }}>
            {actions.includes('edit') && (
                <Button text='Rediger' onClick={triggerEdit} disabled={!allowEditing} style={{ marginRight: '10px' }} />
            )}
            {actions.includes('back') && <Button text='Tilbage' onClick={triggerBack} />}
        </Box>
    );
};

export default Actions;
