import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { IDictionary } from '../utils/types';
import { UserPermissions } from '../../components/restricted/interfaces';
import UtilsString from '../utils/utils-string';
import __ from '../utils/lodash-expansions';
import config from '../../config';
import getStore from '../store';
import { readImageBlob } from '../hooks/fetch-hooks/use-image-fetch';
import { userSlice } from '../hooks/redux-use-user';

export interface AuthResponse {
    id: number | undefined;
    token: string | undefined;

    username: string | undefined;
    role: string | undefined;
    team: string | undefined;
}
// Nedenstående er en decorator der kalder Api.isInit før funktionen den sættes på
// https://stackoverflow.com/a/36351000
const requireInit = () => (target: Api, name: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = (...args: unknown[]) => {
        Api.isInit();
        return originalMethod?.apply(target, args);
    };

    return descriptor;
};

class Api {
    private static _token: string;
    public static get token(): string {
        return this._token;
    }
    public static get header(): IDictionary<string> {
        return { Authorization: `Bearer ${this._token}` };
    }

    //#region Init

    public static async init(token?: string | null): Promise<void> {
        const store = getStore();
        if (UtilsString.IsNullOrWhitespace(token)) {
            store.dispatch({ type: 'user/signOut' });
            // throw new Error("token was undefined or whitespace");
        }
        this._token = token as string;
        const response = await this.get<UserPermissions[]>('/User/Permission?all=true');
        if (!this.ok(response)) return;

        store.dispatch({ type: 'perm/setPerm', payload: response.data });
    }

    public static isInit(): boolean {
        if (UtilsString.IsNullOrWhitespace(this.token)) {
            const store = getStore();
            store.dispatch({ type: 'user/signOut' });
            // throw new Error("token was undefined or whitespace");
        }

        return true;
    }

    //#endregion Init
    //#region Axios

    /**
     * Authenticated Axios.Get
     *
     * Any Api Axios function must always call Api.ok(response) after awaiting, to asure errors are handled properly
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param url A full or partial URL to the api endpoint
     * @param config An AxiosRequestConfig object. Api authenticating headers are automatically added
     * @returns An axios response promise
     */
    @requireInit()
    public static get<T = unknown, D = unknown>(
        url: string,
        config?: AxiosRequestConfig<D>
    ): Promise<AxiosResponse<T, D>> {
        ({ url, config } = this.axiosPrepare(url, config));
        return axios
            .get<T, AxiosResponse<T, D>, D>(url, config)
            .catch((err) => this.axiosCatch<AxiosResponse<T, D>>(err)!);
    }

    /**
     * Authenticated Axios.delete
     *
     * Any Api Axios function must always call Api.ok(response) after awaiting, to asure errors are handled properly
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param url A full or partial URL to the api endpoint
     * @param config An AxiosRequestConfig object. Api authenticating headers are automatically added
     * @returns An axios response promise
     */
    @requireInit()
    public static delete<T = unknown, R = AxiosResponse<T>, D = unknown>(url: string, config?: AxiosRequestConfig<D>) {
        ({ url, config } = this.axiosPrepare(url, config));
        return axios.delete<T, R, D>(url, config).catch((err) => this.axiosCatch<R>(err)!);
    }

    /**
     * Authenticated Axios.post
     *
     * Any Api Axios function must always call Api.ok(response) after awaiting, to asure errors are handled properly
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param url A full or partial URL to the api endpoint
     * @param config An AxiosRequestConfig object. Api authenticating headers are automatically added
     * @returns An axios response promise
     */
    @requireInit()
    public static post<T = unknown, R = AxiosResponse<T>, D = unknown>(
        url: string,
        data?: D,
        config?: AxiosRequestConfig<D>
    ) {
        ({ url, config } = this.axiosPrepare(url, config));
        return axios.post<T, R, D>(url, data, config).catch((err) => this.axiosCatch<R>(err)!);
    }

    /**
     * Authenticated Axios.put
     *
     * Any Api Axios function must always call Api.ok(response) after awaiting, to asure errors are handled properly
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param url A full or partial URL to the api endpoint
     * @param config An AxiosRequestConfig object. Api authenticating headers are automatically added
     * @returns An axios response promise
     */
    @requireInit()
    public static put<T = unknown, R = AxiosResponse<T>, D = unknown>(
        url: string,
        data?: D,
        config?: AxiosRequestConfig<D>
    ) {
        ({ url, config } = this.axiosPrepare(url, config));
        return axios.put<T, R, D>(url, data, config).catch((err) => this.axiosCatch<R>(err)!);
    }

    /**
     * Authenticated Axios.patch
     *
     * Any Api Axios function must always call Api.ok(response) after awaiting, to asure errors are handled properly
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param url A full or partial URL to the api endpoint
     * @param config An AxiosRequestConfig object. Api authenticating headers are automatically added
     * @returns An axios response promise
     */
    @requireInit()
    public static patch<T = unknown, R = AxiosResponse<T>, D = unknown>(
        url: string,
        data?: D,
        config?: AxiosRequestConfig<D>
    ) {
        ({ url, config } = this.axiosPrepare(url, config));
        return axios.patch<T, R, D>(url, data, config).catch((err) => this.axiosCatch<R>(err)!);
    }

    /**
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param response
     * @returns a boolean representation of the response status
     */
    public static ok(response: AxiosResponse<unknown, unknown> | undefined) {
        if (response) return 199 < response.status && response.status < 300;
        return false;
    }

    /**
     * Axios error handler
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param error
     * @returns The response if one was recieved, otherwise undefined.
     * If the response is undefined, it will be caught in the following Api.ok(response) call.
     */
    private static axiosCatch<R>(error: AxiosError): R | undefined {
        if (error.response) {
            // Request made and server responded
            console.error('The server responded with an error.\n', error.response);
            return error.response as unknown as R;
        } else if (error.request) {
            // The request was made but no response was received
            console.error("The server didn't respond.\n", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error("The request wasn't send.\n", error.message);
        }
    }

    /**
     * Completes the url if partial and adds the nessecary configuration for an api call
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param partialUrl the possibly partial url
     * @param config the configuration object
     * @returns and object with the complete url and configuration
     */
    private static axiosPrepare<D = unknown>(partialUrl: string, config?: AxiosRequestConfig<D>) {
        return {
            url: this.createUrl(partialUrl),
            config: this.createConfig(config)
        };
    }

    /**
     * Completes the url for an api call
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param partialUrl
     * @returns The completed url
     */
    public static createUrl(partialUrl: string) {
        if (partialUrl.startsWith('/api')) return config.SERVER_URL_BASE + partialUrl;
        else if (partialUrl.startsWith('/')) return config.SERVER_URL + partialUrl;
        return partialUrl;
    }

    /**
     * Adds the nessecary configuration for an api call
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param config
     * @returns The completed config object
     */
    private static createConfig<D = unknown>(config?: AxiosRequestConfig<D>) {
        const defaultConfig: AxiosRequestConfig<D> = {
            headers: this.header
        };
        return __.deepMerge(defaultConfig, config ?? {});
    }

    //#endregion Axios
    //#region Fetch

    /**
     * * @deprecated use one of the pre-configured axios-functions
     *
     * Api.get
     *
     * Api.delete
     *
     * Api.post
     *
     * Api.put
     *
     * Api.patch
     *
     *
     *
     * Fetch with api authorization
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param input fetch input
     * @param init fetch init
     * @returns Response Promise
     *
     */
    @requireInit()
    public static fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
        // fuldfør strings hvis ikke fuld uri gives
        if (typeof input === 'string') {
            if (input.startsWith('/api')) input = config.SERVER_URL_BASE + input;
            else if (input.startsWith('/')) input = config.SERVER_URL + input;
        }

        if (init !== undefined) {
            if (init.headers !== undefined)
                (init.headers as Record<string, string>)['Authorization'] = `Bearer ${this.token}`;
            else
                init.headers = {
                    Authorization: `Bearer ${this.token}`
                };
        } else
            init = {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            };

        // eslint-disable-next-line no-restricted-syntax
        return fetch(input, init);
    }

    /**
     * Synchronious fetch with api authorization, made to mimic the inputs of the async fetch
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param input fetch input
     * @param init fetch init
     * @returns string: The body's responsetext
     */
    @requireInit() // fetchSync er lavet til at tage samme argumenter som fetch, så det kaldes på samme måde
    public static fetchSynch(input: RequestInfo, init?: RequestInit): string | undefined {
        if (init !== undefined) {
            if (init.headers !== undefined)
                (init.headers as Record<string, string>)['Authorization'] = `Bearer ${this.token}`;
            else
                init.headers = {
                    Authorization: `Bearer ${this.token}`
                };
        } else
            init = {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            };

        const xhr = new XMLHttpRequest();
        xhr.open(init.mode ?? 'get', input.toString(), false);

        // Headers
        Object.keys(init.headers as Record<string, string>).forEach((key) => {
            xhr.setRequestHeader(key, (init?.headers as Record<string, string>)[key]);
        });

        xhr.onerror = (e) => {
            console.error(e);
        };

        xhr.send(init.body as XMLHttpRequestBodyInit);
        if (199 < xhr.status && xhr.status < 300) return xhr.responseText;
        else return undefined;
    }

    //#endregion Fetch
    //#region Auth

    public static async validateToken(token: string): Promise<boolean> {
        const store = getStore();
        try {
            const response = await axios.post<AuthResponse>(`${config.SERVER_URL}/User/ValidateToken`, {
                token
            });
            if (199 > response.status || response.status > 300)
                throw new Error('validation status: ' + response.status);

            store.dispatch(userSlice.actions.setFromAPIResponse(response.data));
            return true;
        } catch (e) {
            store.dispatch(userSlice.actions.signOut());
            return false;
        }
    }

    public static async authenticate(
        userName: string,
        password: string
    ): Promise<{ succes: boolean; authResponse: AuthResponse | undefined }> {
        //const [user, userDispatch] = useContext(UserContext);

        if (UtilsString.IsNullOrWhitespace(userName) || UtilsString.IsNullOrWhitespace(userName))
            return {
                succes: false,
                authResponse: undefined
            };

        // offline test case:
        if (userName === 'offline' && password === 'offline') {
            const authResponse: AuthResponse = {
                id: 4,
                token: 'thisIs.NotAValid.JwtToken',
                username: 'off',
                role: 'admin',
                team: 'JVF'
            };
            return {
                succes: true,
                authResponse: authResponse
            };
        }

        // eslint-disable-next-line no-restricted-syntax
        return fetch(this.createUrl('/User/Authenticate'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userName: userName,
                password: password
            })
        })
            .then((response) => {
                if (response.ok) return response.json();
                throw new Error('Status: ' + response.status);
            })
            .then((json) => {
                return {
                    succes: true,
                    authResponse: json as AuthResponse
                };
            })
            .catch((error) => {
                console.error(error);
                return {
                    succes: false,
                    authResponse: undefined
                };
            });
    }

    //#endregion Auth
    //#region Utility

    /**
     * @deprecated in favour of the useImageFetch hook. Will stay in the project in case it's needed outside functional components
     * fetch der læser respons som dataUrl og returnere billedet som base64 string
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param input fetch input
     * @param init fetch init
     * @returns dataUrl
     */
    public static fetchImage = async (input: RequestInfo, init?: RequestInit): Promise<string> => {
        const response = await this.fetch(input, init);

        if (!response.ok) {
            if (response.status === 410) {
                // Fil er slettet, men har stadig en entry i databasen
                this.fetch(input, { method: 'DELETE' });
                throw new Error("The requested image doesn't exist anymore");
            }
            throw new Error('Request failed');
        }

        const blob = await response.blob();

        if (!blob.type.includes('image')) throw new Error('blob wasnt an image');

        return await readImageBlob(blob);
    };

    //#endregion Utility
}

export default Api;
