/* eslint-disable @typescript-eslint/no-explicit-any */
//! Typechecking turned off as this file is deprecated
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { useAppDispatch, useAppSelector } from '../redux-base-hooks';
import { useEffect, useState } from 'react';

import Api from '../../networking/api';
import { IDictionary } from '../../utils/types';
import config from '../../../config';

interface IUpdateItem<T = any> {
    key: string;
    value: T;
    time: number;
}

type IState = IDictionary<{ value: any; updated: number /* Date */ }>;

const initialState: IState = {};

const reduxFetchSlice = createSlice({
    name: 'reduxFetch',
    initialState: initialState,
    reducers: {
        updateValue: {
            reducer(state: IState, action: PayloadAction<IUpdateItem>) {
                state[action.payload.key] = { value: action.payload.value, updated: action.payload.time };
            },
            prepare(key: string, value: any) {
                return { payload: { key, value, time: Date.now() } };
            }
        }
    }
});

/** @deprecated use useFetch instead */
export const reduxFetchReducer = reduxFetchSlice.reducer;

/** @deprecated as it was build for a deprecated function */
export const predefinedKeys: IDictionary<() => Promise<AxiosResponse<any, any>>> = {
    SearchEnhed: () =>
        axios.get(config.SERVER_URL + '/Drift/Enhed', { headers: { Authorization: `Bearer ${Api.token}` } })
};

// Giver en axios function eller undefined
/** @deprecated as it was build for a deprecated function */
function getPredefinedQueries<T = any>(key: string): (() => Promise<AxiosResponse<T, any>>) | undefined {
    const endpoint = predefinedKeys[key];
    return endpoint;
}

/**
 * @deprecated Use the more versatile useFetch instead
 */
function useReduxFetch<T = any>(
    key: string,
    query?: string | (() => Promise<AxiosResponse<T, any>>),
    useApiKey = true,
    refetchTimer?: number
) {
    const state = useAppSelector((storeState) => storeState.reduxFetch[key]);
    const dispatch = useAppDispatch();

    // Statuser på fetch
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState<Error>();

    const refetch = async () => {
        setIsLoading(true);
        const queryFn =
            typeof query === 'string' ? () => axios.get<T>(query as string) : query ?? getPredefinedQueries<T>(key);

        if (queryFn !== undefined) {
            try {
                const response = await queryFn();
                const data = await response.data;
                dispatch(reduxFetchSlice.actions.updateValue(key, data));
                setIsLoading(false);
                setError(undefined);
                setIsError(false);
            } catch (error) {
                setIsLoading(false);
                setIsError(true);
                setError(error as Error);
            }
        } else {
            console.error(`No query was given, and the key wasn't recognized`);
        }
    };

    // Refetch data hvis timer er udløbet
    if (
        refetchTimer !== undefined &&
        state?.updated !== undefined &&
        state.updated + refetchTimer > Date.now() &&
        !isLoading
    ) {
        refetch();
    }

    useEffect(() => {
        if (state?.value === undefined) refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key, query]);

    return [state?.value, refetch, isLoading, error, isError] as [
        T | undefined,
        () => void,
        boolean,
        Error | undefined,
        boolean
    ];
}

export default useReduxFetch;
