import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import Container from '@mui/material/Container/Container';
import Grid from '@mui/material/Grid/Grid';
import Paper from '@mui/material/Paper/Paper';
import TF from '@mui/material/TextField/TextField';
import styled from '@mui/styles/styled';

//#region Box
export const HorizontalLeftBox = styled(Box)((theme) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
    WebkitJustifyContent: 'start'
}));

export const HorizontalCenterBox = styled(Box)((theme) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
}));

export const HorizontalRightBox = styled(Box)((theme) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    WebkitJustifyContent: 'end'
}));

export const TopLeftBox = styled(Box)((theme) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'start',
    WebkitJustifyContent: 'start',
    alignItems: 'start'
}));

export const TopCenterBox = styled(Box)((theme) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    WebkitJustifyContent: 'center',
    alignItems: 'start'
}));

export const TopRightBox = styled(Box)((theme) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'end',
    WebkitJustifyContent: 'end',
    alignItems: 'start'
}));

export const CenterLeftBox = styled(Box)((theme) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'start',
    WebkitJustifyContent: 'start',
    alignItems: 'center'
}));

export const CenterCenterBox = styled(Box)((theme) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    WebkitJustifyContent: 'center',
    alignItems: 'center'
}));
export const CenterBox = CenterCenterBox;

export const CenterRightBox = styled(Box)((theme) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'end',
    WebkitJustifyContent: 'end',
    alignItems: 'center'
}));

export const BottomLeftBox = styled(Box)((theme) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'start',
    WebkitJustifyContent: 'start',
    alignItems: 'end'
}));

export const BottomCenterBox = styled(Box)((theme) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    WebkitJustifyContent: 'center',
    alignItems: 'end'
}));

export const BottomRightBox = styled(Box)((theme) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'end',
    WebkitJustifyContent: 'end',
    alignItems: 'end'
}));

//#region Box
//#region Button

export const WideButton = styled(Button)((theme) => ({
    width: '100%'
}));

//#endregion Button
//#region Container

export const ScreenContainer = styled(Container)(({ theme }) => ({
    paddingTop: '16px',
    height: '100%'
}));

export const CenterContainer = styled(Container)((theme) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

//#endregion Container
//#region Grid / Grid Item

export const SquareGridItem = styled(Grid)(({ theme }) => ({
    position: 'relative',
    '&::before': {
        display: 'block',
        content: "''",
        paddingBottom: '100%'
    }
}));

//#region Grid / Grid Item
//#region Paper

export const PaddedPaper = styled(Paper)((theme) => ({
    padding: '5px 8px 5px 8px',
    borderRadius: '10px'
}));

//#endregion Paper
//#region TextField

export const TextField = styled(TF)(({ theme }) => ({
    '& legend': {
        width: 'unset'
    }
}));

//#endregion TextField
