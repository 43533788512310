import AutoForm from '../auto-form/v1';
import { DecisionStepFormContentProps } from './interfaces';
import Divider from '@mui/material/Divider/Divider';
import { ItemTypes } from '../auto-form/v1';
import Typography from '@mui/material/Typography/Typography';
import { useCallback } from 'react';

export type { DecisionStepFormContentProps } from './interfaces';

const DecisionStepFormContent = ({ onSubmit, formHandle }: DecisionStepFormContentProps) => {
    const handleCancel = useCallback(() => undefined, []);

    return (
        <>
            <Typography variant='h5'>Sidste Detaljer</Typography>
            <Divider sx={{ mt: 3, mb: 2 }} />
            <AutoForm
                ref={formHandle}
                onSubmit={onSubmit}
                onCancel={handleCancel}
                submitPosition='none'
                grupper={[
                    {
                        id: 1,
                        items: [
                            {
                                id: 'fritekst',
                                label: 'Kommentarer',
                                description: 'Har du andre kommentarer eller detaljer kan de skrives her',
                                type: ItemTypes.Block,
                                placeholder: 'Detaljer...',
                                required: false,
                                sort: 'A'
                            },
                            {
                                id: 'images',
                                label: 'Billeder',
                                description: 'Billeder kan uploades her',
                                type: ItemTypes.File,
                                sort: 'B'
                            }
                        ]
                    }
                ]}
            />
        </>
    );
};

export default DecisionStepFormContent;
