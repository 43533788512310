import Lookup, { DropDownOptions } from 'devextreme-react/lookup';
import { useRef, useState } from 'react';

import Api from '../../shared/networking/api';
import Box from '@mui/material/Box/Box';
import { EventInfo } from 'devextreme/events';
import IconButton from '@mui/material/IconButton/IconButton';
import ReactDOMServer from 'react-dom/server';
import { SelectionChangedInfo } from 'devextreme/ui/drop_down_editor/ui.drop_down_list';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import Typography from '@mui/material/Typography/Typography';
import dxLookup from 'devextreme/ui/lookup';
import { isMobile } from 'react-device-detect';
import useCentralizedSnackbar from '../../shared/hooks/redux-use-centralized-snackbar';
import useFetch from '../../shared/hooks/fetch-hooks/use-fetch';
import yellow from '@mui/material/colors/yellow';

export interface ISearchLookup {
    id: number;
    value: string;
    searchcriteria: string;
    isfavourite?: boolean;
}

export interface ISearchEnhed extends ISearchLookup {
    aktiviteter?: Array<{
        periode?: number;
        antal?: number;
    }>;
}

const GetLookupItemRender = (data: ISearchEnhed) => <LookupItemRender {...data} />;

const LookupItemRender = (data: ISearchEnhed) => {
    const [isFav, setIsFav] = useState(data.isfavourite);
    const { enqueueSnackbar } = useCentralizedSnackbar();
    const onClick = async () => {
        setIsFav(!isFav);
        const response = await Api.post(`/drift/favorit/${data.id}?isFavourite=${!isFav}`);

        if (!Api.ok(response)) {
            setIsFav(isFav);
            enqueueSnackbar('Der skete en fejl', { variant: 'error' });
        }
    };

    return (
        <Box>
            <IconButton key={`${data.id}-favourite`} onClick={onClick} sx={{ width: 40, pt: '1px' }}>
                {isFav ? (
                    <StarIcon sx={{ color: yellow[700], fontSize: 32 }} />
                ) : (
                    <StarBorderIcon sx={{ fontSize: 32 }} />
                )}
            </IconButton>
            <Typography variant='body1' sx={{ display: 'inline', fontSize: '14px' }}>
                {data.value}
            </Typography>
        </Box>
    );
};

/**
 * Props til EnhedLookup
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
interface EnhedLookupProps {
    /** dxLookup's onClosed */
    onClosed?(): void;

    /** On fetch error, if the server doesn't respond with ok */
    onError?(error: Error): void;

    /** set selection on change */
    onSelectionChanged?(selection: ISearchEnhed): void;

    /** Should the lookup open immediately? */
    openOnInitialized?: boolean;
    teamonly?: boolean;
    data?: ISearchEnhed[];
}

const EnhedLookup = ({
    onClosed,
    onError,
    onSelectionChanged,
    openOnInitialized,
    teamonly = false,
    data
}: EnhedLookupProps) => {
    const [dataFetch, , isError, , , refetch, hasFetched] = useFetch<ISearchEnhed[]>(
        `/Drift/Enhed?teamonly=${teamonly}`,
        { redux: { save: true, key: 'drift-search-enhed' }, onError }
    );
    const [selectedItem, setSelectedItem] = useState<ISearchEnhed>();
    const ref = useRef<HTMLDivElement>(null);

    const handleLookupInitialized = (e: { component?: dxLookup | undefined; element?: HTMLElement | undefined }) =>
        openOnInitialized && e.component?.open();
    const handleLookupOpened = () =>
        (ref.current?.querySelector('input.dx-texteditor-input') as HTMLInputElement)?.focus();
    const handleLookupClosed = (e: EventInfo<dxLookup>) => {
        onClosed?.();
        refetch();
    };
    const handleSelectionChanged = (e: EventInfo<dxLookup> & SelectionChangedInfo) => {
        if (e.selectedItem == null) return;

        setSelectedItem(e.selectedItem);
        onSelectionChanged?.(e.selectedItem);
    };

    const getNoDataText = () => {
        if (!hasFetched)
            return ReactDOMServer.renderToStaticMarkup(
                <div className='no-data-container'>
                    <p className='error-p'>Henter information...</p>
                </div>
            );

        if (isError)
            return ReactDOMServer.renderToStaticMarkup(
                <div className='no-data-container'>
                    <p className='error-p'>Det var ingen information på jeres ejendomme</p>
                </div>
            );

        return ReactDOMServer.renderToStaticMarkup(
            <div className='no-data-container'>
                <p>Ingen ejendom fundet</p>
            </div>
        );
    };

    return (
        <Box ref={ref}>
            <Lookup
                dataSource={data ?? dataFetch}
                displayExpr='value'
                searchExpr={['value', 'searchCriteria']}
                showDataBeforeSearch={false}
                minSearchLength={0}
                itemRender={GetLookupItemRender}
                noDataText={getNoDataText()}
                onInitialized={handleLookupInitialized}
                onOpened={handleLookupOpened}
                onClosed={handleLookupClosed}
                onSelectionChanged={handleSelectionChanged}
                selectedItem={selectedItem}
                showCancelButton={!(openOnInitialized && selectedItem === undefined)}
            >
                <DropDownOptions
                    fullScreen={isMobile}
                    hideOnOutsideClick={true}
                    title={'Vælg en ejendom'}
                    minHeight={'500px'}
                />
            </Lookup>
        </Box>
    );
};

export default EnhedLookup;
