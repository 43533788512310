import Http403 from '../../screens/http/403';
import { ReactElement } from 'react';
import Restricted from './restricted';
import { RestrictedRouteProps } from './interfaces';
import { Route } from 'react-router-dom';

/** @deprecated use getRestrictedRoute instead */
export const getRestrictedRouteProps = ({
    permissions,
    path,
    index,
    element
}: RestrictedRouteProps): Parameters<typeof Route>[0] => {
    return {
        path,
        index,
        element: (
            <Restricted permissions={permissions} renderUnauthorized={<Http403 />}>
                {element}
            </Restricted>
        )
    };
};

export const getRestrictedRoute = (props: RestrictedRouteProps): ReactElement => (
    <Route {...getRestrictedRouteProps(props)} />
);
