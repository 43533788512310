import CTLayer from './CTLayer';
import { Cluster } from 'ol/source';
import Feature from 'ol/Feature';
import { FeatureProperty } from './feature-utility';
import Fill from 'ol/style/Fill';
import Geometry from 'ol/geom/Geometry';
import { IDictionary } from '../../../shared/utils/types';
import { IMapLayer } from '../caretaker-map';
import Icon from 'ol/style/Icon';
import RenderFeature from 'ol/render/Feature';
import { SFC } from './react-controls/search-features/control';
import { SVG } from './SVG';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import { ToolStyleKeys } from './EditingControl';

const defaultOptions: IMapLayer = {
    type: 'PIN',
    displayName: '',
    status: undefined,
    minZoom: 0,
    maxZoom: 9999,
    clusterOptions: {}
};

export default class PinLayer extends CTLayer<Cluster> /*VectorLayer<VectorSource<any>>*/ {
    /**
     * Two-dimensional dictionary used to cache styles based on cluster size and match count
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     */
    private styleCache!: IDictionary<IDictionary<Style>>;

    constructor(options: IMapLayer) {
        const _options = { ...defaultOptions, ...options };
        super(_options, {
            zIndex: 1000,
            minZoom: options.minZoom ?? 0,
            maxZoom: options.maxZoom ?? 12
        });

        this.initStyleCache();

        this.createStyle = this.createStyle.bind(this);

        this.set(ToolStyleKeys.Select, this.createStyle);
        this.setStyle(this.createStyle);
    }

    //#region Init

    private initStyleCache() {
        // this.styleCache = {
        //     1: {
        //         0: new Style({
        //             image: new Icon({
        //                 anchor: [0.5, 0.7],
        //                 src: '/Images/map-icons/location-marker-gray.svg',
        //                 opacity: 1,
        //                 scale: 0.6,
        //             })
        //         }),
        //         1: new Style({
        //             image: new Icon({
        //                 anchor: [0.5, 0.7],
        //                 src: '/Images/map-icons/location-marker-red.svg',
        //                 opacity: 1,
        //                 scale: 0.6,
        //             })
        //         }),
        //         2: new Style({
        //             image: new Icon({
        //                 anchor: [0.5, 0.7],
        //                 src: '/Images/map-icons/location-marker-yellow.svg',
        //                 opacity: 1,
        //                 scale: 0.6,
        //             })
        //         }),
        //         3: new Style({
        //             image: new Icon({
        //                 anchor: [0.5, 0.7],
        //                 src: '/Images/map-icons/location-marker-green.svg',
        //                 opacity: 1,
        //                 scale: 0.6,
        //             })
        //         })
        //     }
        // }
        // for (const arr of this.sources) {
        //     const img = new Image(100, 100);
        //     img.onload = () => {
        //         this.styleCache[arr[0]][arr[1]] = new Style({
        //             image: new Icon({
        //                 anchor: [0.5, 0.7],
        //                 opacity: 1,
        //                 scale: 0.6,
        //                 img,
        //                 imgSize: [img.width, img.height]
        //             })
        //         });
        //     }
        //     img.src = arr[2];
        // }
    }
    //'red', 'blue', 'green', 'yellow', 'orange', 'grey'
    private sources: string[] = [
        '/Images/map-icons/location-marker-red.svg',
        '/Images/map-icons/location-marker-blue.svg',
        '/Images/map-icons/location-marker-green.svg',
        '/Images/map-icons/location-marker-yellow.svg',
        '/Images/map-icons/location-marker-orange.svg',
        '/Images/map-icons/location-marker-gray.svg'
    ];

    //#endregion Init

    /**
     * Get cached style if it exists
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param size Number of features in the cluster
     * @param matchCount number of featurs in the cluster matching the current search criteria
     * @returns a style if found, otherwise undefined
     */
    private getCachedStyle(
        size: number,
        matchCount: number | undefined,
        status: number = 0,
        scale?: number
    ): Style | undefined {
        const match = matchCount !== undefined ? (matchCount > 0 ? status : 5) : status;
        const imgString = this.sources[match];

        return new Style({
            image: new Icon({
                anchor: [0.5, 0.7],
                opacity: 1,
                scale: scale ?? 0.6,
                src: imgString,
                imgSize: [100, 100]
            })
        });
        // const tempCache = this.styleCache[size];
        // if (tempCache === undefined)
        //     this.styleCache[size] = {};
        // switch (status) {
        //     default: // red
        //     case 0:
        //     case 16:
        //         return this.styleCache[size][matchCount ?? (size === 1 ? 1 : 'short text')];
        //     case 2: // green
        //     case 17:
        //         return this.styleCache[size][matchCount !== undefined ? matchCount * 3 : 3]
        //     case 18: // grey
        //         return this.styleCache[size][matchCount !== undefined ? matchCount * 0 : 0]
        //     case 1: // yellow
        //     case 19:
        //         return this.styleCache[size][matchCount !== undefined ? matchCount * 2 : 2]
        // }
    }

    /**
     * Set style in cache
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param size Number of features in the cluster
     * @param matchCount number of featurs in the cluster matching the current search criteria
     * @param style The style to cache
     */
    private setCachedStyle(size: number, matchCount: number | undefined, style: Style) {
        const cache = this.styleCache[size];
        if (cache === undefined) this.styleCache[size] = {};
        this.styleCache[size][matchCount ?? (size === 1 ? 1 : 'short text')] = style;
    }

    /**
     * Get or create a style for a feature
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param feature The feature to style
     * @returns a feature style object
     */
    private createStyle(feature: RenderFeature | Feature<Geometry>) {
        const [isMatch, matchCount] = SFC.matchingFeatures(feature);
        const features: (RenderFeature | Feature<Geometry>)[] = feature.get('features');
        const size: number = features?.length ?? 1;
        const data = features?.[0].get(FeatureProperty.Data);
        let style = this.getCachedStyle(size, matchCount, data?.color);

        if (!style) {
            const text = matchCount === undefined ? size.toString() : `${matchCount} af ${size}`;
            style = SVG.createClusterStyle(text, isMatch);
            // style = this._createStyle(size, matchCount);
            this.setCachedStyle(size, matchCount, style);
        }
        console.log(this.getProperties().map.getView().getZoom(), data);
        style.setText(
            new Text({
                font: '12px "Segoe UI", Verdana, Tahoma, sans-serif',
                fill: new Fill({ color: '#FFF' }),
                stroke: new Stroke({
                    color: '#000',
                    width: 2
                }),
                text: this.getProperties().map.getView().getZoom() > 10 ? data?.label ?? '' : ''
            })
        );
        return style;
    }
}
