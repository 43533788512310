import { useEffect, useState } from 'react';
import useFetch, { IFetchOptions } from './use-fetch';

import _ from 'lodash';

const defaultOptions: IFetchOptions = {
    config: {
        responseType: 'blob'
    }
};

/**
 * Fetches image and returns an array of relevant states and functions
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param {string} url The url to fetch from
 * @param {IFetchOptions} options - Options for how to fetch or save data
 * @returns array of values in the following order
 * - image - a dataUrl (string) which acts as an img-source
 * - isLoading - Bool indicating the image is being fetched
 * - isError - Bool indicating an error occured
 * - error - The error object
 * - refetch - Function to manually trigger a refetch
 */
const useImageFetch = (url: string, options?: IFetchOptions) => {
    const fetchOptions = _.merge(defaultOptions, options);

    const [blob, fIsLoading, fIsError, fError, fStatus, fRefetch] = useFetch<Blob>(url, fetchOptions);
    const [image, setImage] = useState<string>();
    const [isLoading, setIsLoading] = useState(() => fIsLoading);
    const [isError, setIsError] = useState(() => fIsError);
    const [error, setError] = useState(() => fError);

    // Start loading
    useEffect(() => {
        if (fIsLoading === true) setIsLoading(fIsLoading);
    }, [fIsLoading]);

    // Sæt isError på fetch error
    useEffect(() => {
        if (fIsError === true) setIsError(fIsError);
    }, [fIsError]);

    // Sæt error på fetch error
    useEffect(() => {
        if (fError !== undefined) setError(fError);
    }, [fError]);

    useEffect(() => {
        if (error !== undefined) console.error(error);
    }, [error]);

    useEffect(() => {
        let isMounted = true;

        const readData = async () => {
            if (blob !== undefined) {
                try {
                    // Er blob et billede?
                    if (!blob.type.includes('image')) throw new Error('blob wasnt an image');

                    // Læs blob til dataUrl
                    const dataUrl = await readImageBlob(blob!);
                    // Set states vis komponent er mounted
                    if (isMounted) {
                        setImage(dataUrl);
                        setIsLoading(false);
                        setIsError(false);
                        setError(undefined);
                    } else {
                        throw new Error("couldn't set image. Component wasn't mounted");
                    }
                } catch (err) {
                    // Sæt error. Logges ikke her, det er op til componenten der bruger denne hook.
                    setIsLoading(false);
                    setIsError(true);
                    setError(err as Error);
                }
            }
        };

        readData();
        return () => {
            isMounted = false;
        };
    }, [blob]);

    return [image, isLoading, isError, error, fStatus, fRefetch] as [
        string,
        boolean,
        boolean,
        Error | undefined,
        number | undefined,
        () => void
    ];
};

export const readImageBlob = async (file: Blob): Promise<string> => {
    return new Promise((resolved, rejected) => {
        const reader = new FileReader();
        reader.onloadend = (e) => {
            if (reader.result == null) {
                rejected(new Error('reader result was null'));
            }
            resolved(reader.result!.toString());
        };
        reader.readAsDataURL(file);
    });
};

export default useImageFetch;
