import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IDictionary } from '../../utils/types';
import { ISliceState } from './interfaces';
import { Ref } from 'react';

export const refStore: IDictionary<Ref<HTMLElement>> = {};

const initialState: ISliceState = [];

const usePortalSlice = createSlice({
    name: 'usePortal',
    initialState: initialState,
    reducers: {
        add: (
            state,
            action: PayloadAction<{
                key: string;
                target: string;
                ref: Ref<HTMLElement>;
            }>
        ) => {
            refStore[action.payload.key] = action.payload.ref;
            return [...state, { key: action.payload.key, target: action.payload.target }];
        },

        remove: (
            state,
            action: PayloadAction<{
                key: string;
            }>
        ) => {
            delete refStore[action.payload.key];
            return state.filter((r) => r.key !== action.payload.key);
        }
    }
});

export default usePortalSlice;
